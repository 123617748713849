import React, { useState, useEffect, useCallback } from 'react'
import { Input } from './Input'
import { classnames } from '../../utils/classnames'
import { convertGoogleResponseToAddress } from '../parcelmaps/utils'


const googleComponents = [
  { googleComponent: 'street_number', location: 'street_number', type: 'long_name' },
  { googleComponent: 'route', location: 'street', type: 'long_name' },
  { googleComponent: 'locality', location: 'city', type: 'long_name' },
  { googleComponent: 'administrative_area_level_1', location: 'state', type: 'short_name' },
  { googleComponent: 'postal_code', location: 'zip', type: 'long_name' },
  { googleComponent: 'administrative_area_level_2', location: 'county', type: 'long_name' },
]

const initialState = {
  items: [],
  active: 0,
}

const GoogleMapsAutocomplete = (props) => {
  const [state, setState] = useState(initialState);

  const [services, setServices] = useState({
    autocompleteService: null,
    placeService: null,
    autocompleteOK: null
  });

  useEffect(() => {
    try {
      setServices({
        autocompleteService: new window.google.maps.places.AutocompleteService(),
        placeService: new window.google.maps.places.PlacesService(
          document.createElement('div')
        ),
        autocompleteOK: window.google.maps.places.PlacesServiceStatus.OK
      });
    } catch (e) {
      console.log('Error in Google Maps: ', e.message);
    }
  }, []);

  const handlePredictions = useCallback((predictions, status) => {
    if (status !== services.autocompleteOK) {
      setState(initialState);
      return;
    }

    const formattedSuggestion = text => ({
      name: text.main_text,
      body: text.secondary_text,
    });

    setState(prev => ({
      ...prev,
      items: predictions.map((place, index) => ({
        index,
        suggestion: place.description,
        placeId: place.place_id,
        raw: place,
        name: formattedSuggestion(place.structured_formatting),
      })),
    }));
  }, [services.autocompleteOK]);

  const getPredictions = useCallback((value) => {
    if (value.length && services.autocompleteService) {
      try {
        services.autocompleteService.getPlacePredictions(
          { input: value },
          handlePredictions
        );
      } catch(e) {
        console.log('getPlacePredictions error: ', e.message);
      }
    }
  }, [services.autocompleteService, handlePredictions]);

  const handleChange = useCallback((event) => {
    event.preventDefault();
    const { value } = event.target;

    if (value.length) {
      getPredictions(value);
    } else {
      setState(prev => ({ ...prev, items: [] }));
    }
    
    props.onChange(event);
  }, [props.onChange, getPredictions]);

  const selectAddress = useCallback((place, index) => {
    const { placeId } = place;
    const { onChange, onSelect } = props;

    services.placeService?.getDetails({ placeId }, (event, status) => {
      onSelect(convertGoogleResponseToAddress(event));
      setState(prev => ({ ...prev, items: [] }));
    });
  }, [props, services.placeService]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setState(prev => ({ ...prev, items: [] }));
    }, 200);
  }, []);

  const handleKeyDown = useCallback((e) => {
    const { key } = e;
    const { active, items } = state;

    if (key === 'ArrowUp' && active > 0) {
      setState(prev => ({ ...prev, active: prev.active - 1 }));
    } else if (key === 'ArrowDown' && active < items.length - 1) {
      setState(prev => ({ ...prev, active: prev.active + 1 }));
    } else if (key === 'Enter' && items.length > 0) {
      e.preventDefault();
      selectAddress(items[active], active);
    }
  }, [state, selectAddress]);

  const { name, onChange, value, label = '', placeholder, className } = props;
  const { items, active } = state;

  return (
    <div className="autocomplete">
      <Input
        label={label}
        placeholder={placeholder ?? "Start typing an address..."}
        name={name}
        className={`address-autocomplete ${className}`}
        onChange={handleChange}
        isRequired={props.isRequired}
        type="text"
        value={value ?? ''}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />
      {items.length > 0 && (
        <div className={classnames(
          'autocomplete-container',
          active === 0 && 'afterActive'
        )}>
          {items.map((place, index) => (
            <div
              className={classnames('autocomplete-item', active === index && 'activeComplete')}
              key={place.placeId}
              onMouseOver={() => setState(prev => ({ ...prev, active: index }))}
              onMouseDown={() => selectAddress(place, index)}
            >
              <div className={classnames('autocomplete-address', active === index && 'activeComplete')}>
                {place.name.name}
              </div>
              {place.name.body}
            </div>
          ))}
          <div className="autocomplete-item autocomplete-footer">
            Powered by <b>Google</b>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleMapsAutocomplete;
