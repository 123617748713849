export function formatParcelResponse(parcel, googleAddress = {}) {
  const mailAddress3 = parcel.mail_address3 || "";
  const matches = mailAddress3.match(/^(.+?)\s+([A-Z]{2})\s+(\d{3,10})$/);
  
  let ownerCity = matches?.[1] || "";
  ownerCity = ownerCity.replace(/,$/, ''); // Remove trailing comma

  const ownerState = matches?.[2] || "";
  const ownerZip = matches?.[3] || "";

  return {
    ...parcel,
    address: {
      address:
        googleAddress?.address ??
        [
          parcel?.addr_number,
          parcel?.addr_street_prefix,
          parcel?.addr_street_name,
          parcel?.addr_street_type,
        ]
          .filter(Boolean)
          .join(" "),
      city: googleAddress?.city ?? parcel?.physcity ?? parcel?.muni_name,
      state: googleAddress?.state ?? parcel?.state_abbr,
      zip: googleAddress?.zip ?? parcel?.census_zip,
      county: googleAddress?.county ?? parcel?.county_name,
    },
    owner: {
      name: parcel?.owner,
      address: parcel.mail_address1,
      city: ownerCity,
      state: ownerState,
      zip: ownerZip,
    },
  };
}

export function validateAddress(currentAddress, parcelAddress) {
  return {
    address:
      currentAddress?.address?.toUpperCase()?.trim() ==
      parcelAddress?.address?.toUpperCase()?.trim(),
    city:
      currentAddress?.city?.toUpperCase()?.trim() ==
      parcelAddress?.city?.toUpperCase()?.trim(),
    state:
      currentAddress?.state?.toUpperCase()?.trim() ==
      parcelAddress?.state?.toUpperCase()?.trim(),
    zip:
      currentAddress?.zip?.toUpperCase()?.trim() ==
      parcelAddress?.zip?.toUpperCase()?.trim(),
    county:
      currentAddress?.county?.toUpperCase()?.trim() ==
      parcelAddress?.county?.toUpperCase()?.trim(),
  };
}

export function validateOwner(currentOwner, parcelOwner) {
  return {
    name:
      currentOwner?.company?.toUpperCase()?.trim() ==
      parcelOwner?.name?.toUpperCase()?.trim(),
    address:
      currentOwner?.address?.toUpperCase()?.trim() ==
      parcelOwner?.address?.toUpperCase()?.trim(),
    city:
      currentOwner?.city?.toUpperCase()?.trim() ==
      parcelOwner?.city?.toUpperCase()?.trim(),
    state:
      currentOwner?.state?.toUpperCase()?.trim() ==
      parcelOwner?.state?.toUpperCase()?.trim(),
    zip:
      currentOwner?.zip?.toUpperCase()?.trim() ==
      parcelOwner?.zip?.toUpperCase()?.trim(),
  };
}

export function convertGoogleResponseToAddress(googleResp) {
  return {
    address: [
      googleResp?.address_components?.find((component) =>
        component.types.includes("street_number")
      )?.short_name,
      googleResp?.address_components?.find((component) =>
        component.types.includes("route")
      )?.short_name,
    ]
      .filter(Boolean)
      .join(" "),
    city:
      googleResp?.address_components?.find((component) =>
        component.types.includes("locality")
      )?.short_name ??
      googleResp?.address_components?.find((component) =>
        component.types.includes("sublocality")
      )?.short_name ??
      googleResp?.address_components?.find((component) =>
        component.types.includes("administrative_area_level_3")
      )?.short_name ??
      googleResp?.address_components?.find((component) =>
        component.types.includes("postal_town")
      )?.short_name,
    state: googleResp?.address_components?.find((component) =>
      component.types.includes("administrative_area_level_1")
    )?.short_name,
    zip: googleResp?.address_components?.find((component) =>
      component.types.includes("postal_code")
    )?.short_name,
    county: googleResp?.address_components?.find((component) =>
      component.types.includes("administrative_area_level_2")
    )?.short_name,
  };
}
