import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react'
import { useParams, Prompt } from 'react-router-dom'
import { withTransaction } from '@elastic/apm-rum-react'

import { JobAppContext } from '../../GlobalState';

import { DateTime } from "luxon";

import {
	canSubmitJobs,
	newJob,
	finalizeJob,
	uploadAttachment, deleteAttachment, updateStatus
} from '../../api'

import ReCAPTCHA from "react-google-recaptcha";

import JobSettings from '../pages/JobSettings'

import { Button, Alert, Table, Nav, NavLink, NavItem } from 'reactstrap'
import { Input }        from '../partials'
import FileDropper      from '../partials/FileDropper'
import Place            from '../partials/Place'

import ProjectLocation from '../partials/ProjectLocation'
// import { Select }       from '../partials'
import { ListDropDown } from '../partials/ListDropDown'
import DateField    	from '../partials/DateField'
import Form 			from '../Form/Form'
import CurrencyField  	from '../Form/CurrencyField'

import { useQueryClient } from '@tanstack/react-query';
import { useJobForm, useUpdateJobForm, useUpdateJobFormLink, jobKey } from '../hooks';

import _ from 'lodash-es';
import { useParcelByAddress } from '../parcelmaps/api';
import useDebounce from '../hooks/useDebounce';

const custom_texts = [
	{
		type: 'alpha', pattern: '^[A-Za-z]$'
	},
	{
		type: 'alphanum', pattern: '^[0-9A-Za-z]$'
	},
	{
		type: 'number', pattern: '^\d*(\.\d{0,2})?$' // numeric values, with two decimal precision
	},
	{
		type: 'date', pattern: '^[0-9]{2}[-\s\.\/]?[0-9]{2}[-\s\.\/]?[0-9]{4}$'
	},
	{
		type: 'email', pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$'
	},
	{
		type: 'phone', pattern: '^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'
	}
];

const default_form_config = {  // default config
	"fields": [
		{ "name": "project_name", "label": "Project Name", "active": true, "req": true }
	], "allow_none": true
};

const formatDate = (date) => {
	if (date === "" || date === undefined || date === null || date.startsWith("0000")) return "";
	const newDate = DateTime.fromSQL(date).toLocaleString();
	return newDate;
}

const ShowForm = (props) => {

	const queryClient = useQueryClient()

	const {
		user, isAuthenticated, accessToken,
	} = useContext(JobAppContext);

	const params = useParams();
	const cacheKey = jobKey(params);

	const { data: tanData = {}, isLoading, ...jobFormApi } = useJobForm(params, accessToken)
	const { mutate, ...updateJobFormApi } = useUpdateJobForm({
		jobId: tanData?.id
	})
	const { mutate: linkSave, ...updateJobFormLinkApi } = useUpdateJobFormLink({
		jobId: tanData?.id, uuid: tanData?.uuid
	})

	const saved = updateJobFormApi?.isSuccess || updateJobFormLinkApi?.isSuccess;
	const isSaving = updateJobFormApi?.isLoading || updateJobFormLinkApi?.isLoading;
	const isError = updateJobFormApi?.isError || updateJobFormLinkApi?.isError;

	let dragEnterCounter = 0;
	let prettifiedDocs = false;

	const [ canSubmit, setCanSubmit ] = useState(false);
			
	const [ isBlocking, setIsBlocking ] = useState(false);
	const [ isEditing, setIsEditing ] = useState(false);
	const [ isUploading, setIsUploading ] = useState(false);

	const [ filesUploaded, setFilesUploaded ] = useState(false);
	const [ messageStatus, setMessageStatus ] = useState('');
	const [ uploadResult, setUploadResult ] = useState('');

	const [ uuid, setUuid ] = useState(params.uuid ?? 0);
	const [ appid, setAppid ] = useState(params.appid ?? 0);

	const [ project_name, setProjectName ] = useState(''); // Display of Project Name, also used as "guest" form field config
	const [ reference_no, setReferenceNo ] = useState(''); // Display of Reference No, also used as "guest" form field config

	// holds all remaining form field answers
	const [ answers, setAnswers ] = useState();
	// holds all attachments for the job
	const [ attachments, setAttachments ] = useState([]);
	const [ dragOver, setDragOver ] = useState(false);

	// validation for "new" jobs
	const [ recaptcha, setRecaptcha ] = useState('');

	const id = tanData?.id ?? 0;

	const message = tanData?.form?.message ?? '';
	const footnote = tanData?.form?.footnote ?? '';
	const file = tanData?.form_variant?.file ?? '';
	const status = tanData?.status ?? 'open';

	const percent = tanData?.progress?.percent ?? 0;
	const remaining = tanData?.progress?.remaining ?? 0;

	const assocChoices = useMemo(() => 
		tanData?.form?.sections?.flatMap(section => section.fields)
		.find(field => field.type_id === 25 && field.type === 'assoc_choice')?.choices,
		[tanData?.form]
	)

	const assocChoiceField = useMemo(() => 
		tanData?.form?.sections?.flatMap(section => section.fields)
		.find(field => field.type_id === 25 && field.type === 'assoc_choice'),
		[tanData?.form]
	)

	const currSelectedRole = answers?.[assocChoiceField?.id]?.value;
	const isUserOwner = useMemo(() => {
		let role_choice = assocChoices?.find(choice => choice.id == currSelectedRole ? choice.value : null);
		return role_choice?.value == "3" ? true : false;
	}, [assocChoices, currSelectedRole])
	
	// ReportAllUSA parcel data
	const projectAddressId = useMemo(() => 
		tanData?.form?.sections?.flatMap(section => section.fields)
		.find(field => (field.type === "address" || field.type === "address_plus") && field.type_id === 2)?.id,
		[tanData?.form]
	)

	const customerFieldId = useMemo(() => 
		tanData?.form?.sections?.flatMap(section => section.fields)
			.find(field => (field.type === 'address' || field.type === 'address_plus') && field.type_id === null)?.id,
		[tanData?.form]
	)

	const ownerFieldId = useMemo(() => 
		tanData?.form?.sections?.flatMap(section => section.fields)
		.find(field => (field.type === 'address' || field.type === 'address_plus') && field.type_id === 3)?.id,
		[tanData?.form]
	)

	useEffect(() => {

		if (tanData) {
			// console.log('tanData', tanData)
			setProjectName(tanData.name);
			setReferenceNo(tanData.reference_no);
			setAnswers(tanData.answerData);
			setUuid(tanData.uuid || '');
			setAttachments(tanData.attachments);
		}

		if ( isAuthenticated ) {
			// user check -> does user have cnt_id and api_key set on their db record?
			canSubmitJobs().then(data => {
				setCanSubmit(data.submittable);
			})
		}
    
	}, [tanData])

	/* ***** Executed by clicking the "Finalize" button (available on "open" jobs) ***** */
	const finalizeButton = () => {
		
		finalizeJob({ id, uuid }).then(data => {
			
			// updating the job status will update the readonly state of the sections
			queryClient.setQueryData(cacheKey, {
				...queryClient.getQueryData(cacheKey),
				status: data['job'].status
			});
			
			props?.updateTabs(data['job'].status);
			window.scrollTo(0, 0);

		}, error => {
			console.log('error finalizing job', error)
		})

	}

	/* ************************************************************************ */
	/* ***** Handle File Uploads                                          ***** */
	/* ************************************************************************ */
	const attachFile = event => {
		const { target } = event
		const { value, name, files } = target

		if ( files.length > 0 ) {
			// disable buttons: "Save Progress", "Submit Job Information", Upload Dropzone
			// Add "queued" file(s) to table
			setIsUploading(true);
			setFilesUploaded(false);
			setMessageStatus('');
			setUploadResult('');

			// Create FormData, and append file(s) to it
			let formData = new FormData()
			for( var i=0; i<files.length; i++ ) {
				formData.append('files[]', files[i]);
			}
			formData.append('job_id', id);
			formData.append('company_id', tanData?.form?.company_id);
			initiateUpload(formData)
		}
	}

	const removeAttachment = event => {
		const { target } = event
		const { value, name } = target

		setFilesUploaded(false); // clears upload status message

		deleteAttachment(value).then(data => {
			let newAttachList = [...attachments]
			for (var i=0; i<newAttachList.length; i++) {
				if ( newAttachList[i]['id'] == data['attachment_id'] ) {
					newAttachList.splice(i, 1); break;
				}
			}
			setAttachments([
				...newAttachList
			])
		})
	}

	/* ***** FileDropper Event Handlers ***** */
	// const setDragOver = (value) => {
	// 	
	// }

	const fileRetrieved = (files) => {
		if ( files.length > 0 ) {
			// Add "queued" file(s) to table
			setIsUploading(true);
			setFilesUploaded(false);
			setMessageStatus('');
			setUploadResult('');

			// Create FormData, and append file(s) to it
			let formData = new FormData()
			for( var i=0; i<files.length; i++ ) {
				formData.append('files[]', files[i])
			}
			formData.append('job_id', id)
			formData.append('company_id', tanData?.form?.company_id);
			initiateUpload(formData)
		}
	}

	/*
		actually do the upload / response, called from:
		-> attachFile (click and select file(s) from browse window)
		-> fileRetrieved (drag-n-drop file(s))
	*/
	const initiateUpload = ( formData ) => {
		uploadAttachment(formData)
			.then(data => data.json())
			.then(data => {
			
				// Clear "file" input element
				// document.getElementById('attachFile').value = ''

				setIsUploading(false);
				setFilesUploaded(true);

				if ( data['error'] ) {
					setMessageStatus('danger');
					setUploadResult("Error: " +data['error']);
				} else {
					// Update state value of attachments, to show "completed" uploads
					setMessageStatus('success');
					setUploadResult('Upload completed successfully!');
				}
				setAttachments([...data, ...attachments])
			})
	}

	const selectFile = () => {
		// 
		document.getElementById('attachFile').click();
	}
	/* ************************************************************************ */
	/* ************************************************************************ */

	const handleChange = event => {
		const { target } = event
		const { type, name, value, checked } = target

		const name_split = name.split('.')
		const namespace = name_split[name_split.length - name_split.length]
		const field = name_split[name_split.length - 1]

		setIsBlocking(true);

		if (name_split.length > 2) { // address field change
			
			const mid = name_split[1]
			if ( type == 'checkbox' ) { // none/unknown options

				if ( checked ) {
					let updateAddr = {
						...answers[namespace].address
					}
					
					updateAddr['company']   = mid;
					updateAddr['name']      = '';
					updateAddr['phone']     = '';
					updateAddr['address']   = mid;
					updateAddr['address_2'] = '';
					updateAddr['city']      = mid;
					updateAddr['state']     = '';
					updateAddr['zip']       = mid;
					updateAddr['county']    = '';
					updateAddr['email']     = '';

					setAnswers(prevState => ({
						...prevState,
						[namespace]: {
							...prevState[namespace],
							address: updateAddr
						}
					}))
				}

			} else { // address block text/email/etc field changes

				setAnswers(prevState => ({
					...prevState,
					[namespace]: {
						...prevState[namespace],
						[mid]: {
							...prevState[namespace][mid],
							[field]: value,
						},
					},
				}))
			}

		} else if (name_split.length === 2) { // ??
			
			setAnswers(prevState => ({
				...prevState,
				[namespace]: {
					...prevState[namespace],
					[field]: value,
				},
			}))

		} else {    // text or choice field update

			setAnswers(prevState => ({
				...prevState,
				[name]: {
					...prevState[name],
					id: name,
					value: value,
				},
			}))

		}
	}

	const updateAnswers = (changes) => {
		// Set form state flags once
		setIsBlocking(true);
		// Update all fields in one state update
		setAnswers(prevState => {
			let newState = { ...prevState };

			_.merge(newState, changes);
			
			return newState;
		});
	};
	
	const handleDateChange = (field_id, value) => {
		setIsBlocking(true);
		setAnswers(prevState => ({
			...prevState,
			[field_id]: {
				...prevState[field_id],
				id: field_id,
				value: value,
			}
		}))
	}

  	const handle_project_configs = (event) => {
		const { name, value } = event.target
		
		setIsBlocking(true);
		switch(name) {
			case "project_name": setProjectName(value); break;
			case "reference_no": setReferenceNo(value); break;
			default:
				
		}
	}

	const role_update = event => {
		const { target } = event;
		const { value, name, type } = target;

		/*
		let choice, ap;
		if (type === 'select-one') {
			const dataset = target.options[target.selectedIndex].dataset;
			choice = dataset.choice;
			ap = dataset.ap; // additional_party flag on assoc_choice field's radio buttons
		} else {
			choice = target.getAttribute('data-choice');
			ap = target.getAttribute('data-ap'); // additional_party flag on assoc_choice field's radio buttons
		}
		*/

		// update answers state
		const newAnswerData = {
			...answers,
			[name]: {
				...answers[name],
				value: value,
			},
		};
		setAnswers(newAnswerData);
	}

	// called when saving draft (while job is "open" status)
	const saveDraftUpdates = event => {
		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}
		// job is from a link
		if ( uuid ) {
			// essentially the same as "mutate" below, but this does not require authentication
			// TODO: look into combining these two methods, and use presence of uuid as distinguishing factor
			linkSave(answers, {
				onSuccess: (data) => {
					setIsBlocking(false);
				}
			})
		} else {
			// Call the mutate function with form data when the form is submitted
			mutate(answers, {
				onSuccess: (data) => {
					setIsBlocking(false);
				}
			});
		}
	}

	// only called from "submit" button from "guest" job form link (user ID will always be null)
	const saveAndFinalize = () => {

		// clear any old submission error messages
		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}

		let postData = {
			'appid': appid,
			'project_name': project_name,
			'reference_no': reference_no,
			'recaptcha': recaptcha,
			'answers': {
				...answers
			},
			'attachments': attachments.map(a => a.id)
		}

		newJob(postData).then((response) => {

			if ( response['success'] ) {
				
				setIsEditing(false);
				setIsBlocking(false);

				// updating cache with new job status will handle putting job into readonly state
				// updating answerData will handle ensuring the user's submitted answers are displayed after job submission
				queryClient.setQueryData(cacheKey, {
					...queryClient.getQueryData(cacheKey),
					// id: response.id,
					status: 'pending',
					answerData: response.answerData
				});

				window.scrollTo(0, 0);

			} else {
				console.log('error saving job');
			}
			
		});
		
	}

	// ----------------------------------------------------------------------------------

	const saveEdit = event => {
		let submitMessage = document.querySelector('#submitBtnMsg')
		if ( submitMessage ) {
			submitMessage.style.display = 'none';
		}
		mutate(answers, {
			onSuccess: (data) => {
				setIsBlocking(false);
			}
		});
	}

	// ----------------------------------------------------------------------------------

	const handleReCaptcha = value => {
		setRecaptcha(value);
	}

	const checkSectionVisibility = (section) => {

		let visible = true;

		if ( currSelectedRole ) {						
			// get the role choice's value and additional_party ("extra") values for the user's selected role
			let choice = assocChoiceField?.choices.find(c => c.id == currSelectedRole)?.value;
			let ap = assocChoiceField?.choices.find(c => c.id == currSelectedRole)?.extra;
			
			// check section for address fields that are contractual chain members
			section.fields.forEach(field => {
				if (field.type === 'address') {
					if (field?.mapped_type?.reference === 'project_location' || isNaN(field.type_id)) return;
					if (field.type_id > 2 && field.type_id <= 8) {
						if (ap == 1) {
							visible = choice >= field.type_id ? true : false;
						} else {
							visible = choice > field.type_id ? true : false;
						}
					} else {
						visible = true;
					}
				}
			});
		}
		return visible;
	}

	if (isLoading || !answers) {
		return (
			<div className="card">
				<h2 className="card-header">
					NCS Job App
				</h2>
				<div className="card-body">
					<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading job form and data
				</div>
			</div>
		);
	}

	// const { id, uuid, appid, form, attachments, status, remaining, custom_style } = this.state

	let field_config = JSON.parse(tanData?.form?.config) || default_form_config;
	const effectiveOwnerId = isUserOwner ? customerFieldId : ownerFieldId;

	return (
		<>
		{isError && (
			<Alert color="danger" className="my-3">
				Error Loading Job Form Information. Please contact an Admin for support.
			</Alert>
		)}
		<div className="row">
			
			{!isLoading && ((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
				<div className='d-none d-xl-block col-12 col-xl-2'>
					<div className="quickNav">
						<h5>Quick Navigation</h5>
						<Nav id="quick-nav" vertical>
							{tanData?.form?.sections.map(section => {
								if (!checkSectionVisibility(section)) return;
								return (
									<NavItem key={section.id} className={"border-bottom"}>
										<NavLink href={`#${section.title.replace(' ', '-')}`}>
											{section.title}
										</NavLink>
									</NavItem>
								)
							})}
							<NavItem className={"border-bottom"}>
								<NavLink href={`#attachments`}>
									Attachments
								</NavLink>
							</NavItem>
						</Nav>
					</div>
				</div>
			)}

			<div id="form-page" className="col-12 col-xl-10" data-spy="scroll" data-target="#quick-nav" data-offset="0" style={{ display: 'relative' }}>

				{tanData?.custom_style != null && tanData?.custom_style != '' && (
					<link rel="stylesheet" type="text/css" href={'../client/' + tanData?.custom_style} />
				)}

				{status == 'pending' && ((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
					<Alert color="success" className="mt-3">
						Form Submission is complete! Please contact an Admin if you have any questions or concerns.
					</Alert>
				)}

				{/* status == 'submitted' && (
					<Alert color="success" className="mt-3">
						This job has been imported. Please contact an Admin if you have questions or concerns.
					</Alert>
				) */}
				
				{((status == 'open' || status == 'new') || (
					status == 'pending' && (uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)
				)) && !isLoading && (
					<>
						<div className="d-flex mb-2">
							{file && (
								<img alt="Logo" id="form-logo" src={`${window.app_api}/${file.location}`} /> 
							)}
							<div className="flex-grow-1">
								<h3 className="Title">{status == 'new' ? 'New Project' : project_name}</h3>
							</div>
						</div>
						{message && (
							<div className="staticTextField">{message}</div>
						)}
					</>
				)}

				<Form id="formContainer" className={(status === 'pending' || status === 'submitted') ? 'bg-light' : ''} submit={status == 'new' ? saveAndFinalize : finalizeButton}>

					<Prompt when={isBlocking} message="You have unsaved changes! Are you sure you want to continue?" />

					<div className="fieldBuilderPreview">

					{isLoading ? (
						<div className="card">
							<h2 className="card-header">
								NCS Job App
							</h2>
							<div className="card-body">
								<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading job form and data
							</div>
						</div>
					) : (
						<>

						{status == 'new' && (
							<div id="Project-Details" className="card">
								<h2 className="card-header">
									Project Details
								</h2>
								<div className="card-body">
									<div className="form-group">
									
										{field_config?.fields?.map((field, idx) => {
											if ( !field.active ) { return null; }
											return (
												<div key={idx} className="form-row">
													<div className="col">
														<Input
															label={field.label}
															description=""
															type="text"
															name={field.name}
															onChange={handle_project_configs}
															isRequired={field.req ? 1 : 0}
															value={field.name == 'project_name' ? project_name : reference_no}
														/>
													</div>
												</div>
											)
										})}

									</div>
								</div>
							</div>
						)}

						{(!uuid && !appid && isAuthenticated && status != "open") && (
							<JobSettings />
						)}

						{tanData?.form?.sections.map(section => {

							// check if user has selected their role on the project, and set section visibility accordingly
							if (!checkSectionVisibility(section)) return;

							// status is open or new, and section is not readonly
							const defaultReadOnlyStatus = !((status == 'open' || status == 'new') && !section.readonly)
							return (
								<SectionComponent
									key={section.id}

									projectAddressId={projectAddressId}
									effectiveOwnerId={effectiveOwnerId}
									
									status={status}
									section={section}
									readOnly={defaultReadOnlyStatus}
									handleChange={handleChange}
									role_update={role_update}
									
									field_config={field_config}

									answers={answers}
									updateAnswers={updateAnswers}
									// currentParcelApi={currentParcelApi}
									saveEdit={saveEdit}
									
									handleDateChange={handleDateChange}
									isSaving={isSaving}
									uuid={uuid}
									appid={appid}
								/>
							)
							
						})}

						<div id="attachments" className={
							"card" +(
								(status === 'pending' || status === 'submitted') ? ' bg-light' : '') +
								(status === 'pending' && isEditing ? ' border border-danger' : '')
							
						}>
							<h2 className="card-header">
								Attachments
								{!uuid && !appid && status == 'pending' && (
									<>
										{!isEditing ? (
											<i className="ml-3 far fa-edit" role="button" onClick={() => setIsEditing(true)} />
										) : (
											<Button className="btn-sm ml-2" onClick={() => setIsEditing(false)} disabled={isSaving}>
												Cancel
											</Button>
										)}
									</>
								)}
							</h2>
							<div className="card-body">
								
								{filesUploaded && !isUploading && (
									<div className={"alert alert-" +messageStatus+ " col"} role="alert">
										{uploadResult}
									</div>
								)}

								{isUploading && (
									<div className="m-2 p-4" style={{ border: 'solid 1px #CACACA', borderRadius: '10px' }}>
										<i className="fas fa-sync-alt fa-spin mr-1" ></i> Uploading Attachment...
									</div>
								)}

								{( (status == 'open' || status == 'new' || isEditing) && !isSaving && !isUploading ) && (
									<>
										<input type="file" name="attachFile" id="attachFile" multiple onChange={attachFile} style={{ display: 'none' }} />
										<FileDropper setDragState={setDragOver} fileRetrieved={fileRetrieved} onClick={selectFile} className={dragOver ? 'active ' : 'col'}>
											<div className="m-2 p-4">
												<span className="fa fa-upload pr-4" />Click or Drop Files Here to Upload
											</div>
										</FileDropper>
									</>
								)}

								<div className="form-group col mt-3">
									{attachments.length > 0 ? (
										<Table responsive>
											<thead>
												<tr>
													<th>Count</th>
													<th>Name</th>
													<th>Uploaded On</th>
													<th>&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												{attachments.map( (attachment, index) => (
													<tr key={attachment.id}>
														<td>{index+1}</td>
														<td>
														<a href={`/uploads/${attachment.location}`} target="_blank">{attachment.file_name}</a>
														</td>
														<td>{formatDate(attachment.created_at)}</td>
														<td>
															{attachment.status !== 'uploaded' && (status == 'open' || status == 'new' || isEditing) ? (
																<Button name={"del_btn_"+attachment.id} id={"del_attach_"+attachment.id} value={attachment.id} onClick={removeAttachment}>Delete</Button>
															) : (
																<span>&nbsp;</span>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									) : (
										<span>No Attachments Found</span>
									)}
								</div>
							</div>
						</div>

						{status == 'new' && (
							<div className="formFooter mb-3">
								<ReCAPTCHA sitekey="6Lde__IUAAAAAO1yiKUd--eHw8URoOPRt7hz-PYS" onChange={handleReCaptcha} className="mb-3" />
								
								{updateJobFormLinkApi.isSaving ? (
									<div>
										<i className="fas fa-sync-alt fa-spin mr-1" ></i> Submitting Form
									</div>
								) : (
									<Button type="submit" color={recaptcha == '' ? 'secondary' : 'primary'} className="validate" disabled={recaptcha == ''}>
										Submit Form
									</Button>
								)}
								
								<Alert color="danger" id="submitBtnMsg" className="ml-3" style={{ display: 'none' }}></Alert>

								<div className="pt-3">
									<cite>
										<i className="fas fa-exclamation-circle"></i> Note: All fields marked with a blue <span className="required-field">Required</span> badge are required to Submit.
									</cite>
								</div>
							</div>
						)}

						{status == "open" && (
							<ActionButtons
								isBlocking={isBlocking}
								isSaving={isSaving}
								saved={saved}
								isUploading={isUploading}

								btnClick={saveDraftUpdates}
								percent={percent}
								remaining={remaining} />
						)}

						{/*
							<ol>
								<li>This is an extension of credit and ultimately I am responsible for payment of materials.</li>
								<li>This is not a residential project and I have not received any form of down payment for this job.</li>
								<li>Terms on project accounts will never exceed 60 days and interest will accrue when applicable for past due balances.</li>
								<li>A letter of furnishing will be sent to all parties above via certified mail as required by state.</li>
								<li>I will pay Master Halco for materials related to this project as soon as I am paid.</li>
								<li>I agree to additional non-payment notices that will be sent out to all parties involved should the project not be paid within terms based on the specific state requirements.</li>
								<li>Tax will be charged unless required state tax forms are provided during setup.</li>

								<li>I understand that as a material supplier, Master Halco does not participate in retainage.</li>
							</ol>
						*/}

						{((status === 'open' || status === 'new') && footnote) && (
							<div className="staticTextField">{footnote}</div>
						)}
						</>
					)}

					</div>
				</Form>
			</div>
		</div>

		{!isLoading && (
			<div className="row">
				{((uuid !== '' && uuid !== 0) || (appid !== '' && appid !== 0)) && (
					<div className="d-none d-xl-block col-12 col-xl-2">
					</div>
				)}
				<div className="text-right col-12 col-xl-10">
					<NavLink href={`#page-top`}>
						&uarr; Back to Top
					</NavLink>
				</div>
			</div>
		)}
		</>
	)
}

const ActionButtons = ({
	saved, isBlocking, isSaving, isUploading, btnClick, percent, remaining
}) => {

	return (
		<div className="text-right formFooter mb-2 sticky-footer">
			<div className="pb-3">
				{(percent == 100 && !isBlocking) && (
					<cite>
						<i className="fas fa-exclamation-circle"></i> Click the "Finalize" button to complete your job data entry
					</cite>
				)}
			</div>
			{saved && <Alert color="success" style={{ display: 'inline', marginRight: '20px' }}>
				Form saved successfully!
			</Alert>}
			<Alert color="danger" id="submitBtnMsg" className="mr-2" style={{ display: 'none' }}></Alert>

			{remaining > 0 && (
				<span className="mr-3 font-weight-bold text-muted">
					{remaining+ ' required field' +(remaining > 1 ? 's' : '')+ ' remaining'}
				</span>
			)}

			<Button 
				onClick={btnClick} 
				disabled={isSaving || isUploading} 
				color={percent < 100 ? 'primary' : 'secondary'}
				title="This button will validate your data entry and enable the Finalize button"
			>
				{isSaving ? 'Saving...' : 'Save Changes'}
			</Button>
			
			{(percent == 100 && !isBlocking) ? (
				<Button type="submit" color='primary' className="validate ml-3">
					Finalize
				</Button>
			) : (
				<Button type="submit" color='secondary' disabled={true} outline className="validate ml-3">
					Finalize
				</Button>
			)}
		</div>
	)

}

const SectionComponent = ({
	section, 
	status, 
	readOnly = true, 
	field_config, 
	handleChange, 
	role_update, 
	updateAnswers, 
	projectAddressId, 
	answers, 
	saveEdit, 
	isSaving, 
	handleDateChange, 
	uuid, 
	appid, 
	effectiveOwnerId,
	choice
}) => {

	const [ isEditing, setEditing ] = useState(!readOnly);

	useEffect(() => {
		// console.log('updating readOnly', readOnly)
		setEditing(!readOnly)
	}, [readOnly])

	const projectAddress = useDebounce(answers?.[projectAddressId]?.address, 2000);

	const owner = answers?.[effectiveOwnerId]
	// const currSelectedRole = answers?.[assocChoiceField?.id]?.value;

	const currentParcelApi = useParcelByAddress(projectAddress ?? {});
	const currentParcel = currentParcelApi?.data?.[0];

	const handleSave = () => {
		saveEdit()
		setEditing(false)
	}

	return (
		<div id={`${section.title.replace(' ', '-')}`}>
			<div className={
				"card" + 
				(
					(status === 'pending' || status === 'submitted' ? ' bg-light' : '') + 
					(status === 'pending' && isEditing ? ' border border-danger' : '')
				)}
				key={section.id}>
				<h2 className="card-header">
					{section.title}
					{!uuid && !appid && status == 'pending' && (
						<>
							{!isEditing ? (
								<i className="ml-3 far fa-edit" role="button" onClick={() => setEditing(true)} />
							) : (
								<>
									<Button className="mx-2 btn-sm" onClick={handleSave} disabled={isSaving}>
										{isSaving ? "Saving" : "Save"}
									</Button>
									<Button className="btn-sm" onClick={()=>setEditing(false)} disabled={isSaving}>
										Cancel
									</Button>
								</>
							)}
						</>
					)}
				</h2>
				<div className="card-body">

				{section.fields.length !== 0 && section.fields.map((field, key) => {
					
					var custom_found = custom_texts.find(typ => typ.type == field.type);
					return (
						<Fragment key={field.id}>

						{/* field.type === 'hidden' && (
							<input type="hidden" name={field.id} id={field.title} value={field.defaultValue} />
						) */}

						{(field.type === 'address' || field.type === 'address_plus') && (
							
							<>
							{field.type_id == 2 ? (
								<ProjectLocation 
									projectFieldId={field.id}
									
									effectiveOwnerId={effectiveOwnerId}

									value={answers[field.id]}
									onChange={handleChange}
									owner={owner}
									isRequired={field.required}
									updateAnswers={updateAnswers}
									currentParcel={currentParcel}
									currentParcelApi={currentParcelApi}
									fldType={field.type}
									type_id={field.type_id}
									readOnly={!isEditing}
									fields={field.field_configs}
									style={field.style ? JSON.parse(field.style) : null}
								/>
							) : (
								<Place
									fldType={field.type}
									description={field.description}
									hasContact={field.type_id != 2 ? true : false}
									isRequired={field.required}
									label={field.title + ' '}
									onChange={handleChange}
									updateAnswers={updateAnswers}
									value={answers[field.id]}
									parent={field.id}
									type_id={field.type_id}
									allow_none={field_config.allow_none}
									readOnly={!isEditing}
									fields={field.field_configs}
									style={field.style ? JSON.parse(field.style) : null}
								/>
							)}
							</>

						)}

						{custom_found && (
							<div className="form-group">
								<div className="form-row">
									<div className="col">
										{custom_found.type == 'date' ? (  
											<DateField
												name={field.id}  
												description={field.description}
												label={field.title + ' '}
												field_id={field.id}
												onChangeDate={handleDateChange}
												isRequired={field.required}
												value={formatDate(answers?.[field.id]?.value) ?? ''}
												readOnly={!isEditing}
											/>
										) : (
											<Input
												label={field.title}
												description={field.description}
												type={custom_found.type}
												pattern={custom_found.pattern}
												name={field.id}
												onChange={handleChange}
												isRequired={field.required}
												value={answers?.[field.id]?.value ?? ''}
												readOnly={!isEditing}
											/>
										)}
									</div>
								</div>
							</div>
						)}

						{field.type === 'text' && (
							<div className="form-group">
								<div className="form-row">
									<div className="col">
										<Input
											label={field.title}
											description={field.description}
											name={field.id}
											onChange={handleChange}
											isRequired={field.required}
											value={answers?.[field.id]?.value ?? ''}
											readOnly={!isEditing}
										/>
									</div>
								</div>
							</div>
						)}

						{field.type === 'currency' && (
							<div className="form-group">
								<div className="form-row">
									<div className="col">
										<CurrencyField
											label={field.title}
											description={field.description}
											name={field.id}
											onChange={handleChange}
											isRequired={field.required}
											value={answers?.[field.id]?.value ?? ''}
											readOnly={!isEditing}
										/>
									</div>
								</div>
							</div>
						)}

						{(field.type === 'choice' || field.type === 'assoc_choice') && (
							<div className="form-group">
								<div className="form-row">
									<div className="col">
										<ListDropDown
											className="form-group input-wrapper"
											label={field.title}
											description={field.description}
											name={field.id}
											selected={answers?.[field.id]?.value ?? ''}
											onChange={field.type === 'choice' ? handleChange : role_update}
											items={field.choices}
											isRequired={field.required}
											readOnly={!isEditing}
										/>
									</div>
								</div>
							</div>
						)}

						{/* TODO: Change jobinformation.fields.description to "blob" type */}
						{/* allow formatting/html in blob data */}
						{(field.type == 'instructions' && (status == 'open' || status == 'new')) && (
							<div className="row">
								<div className="col">
									<div className="staticTextField">{field.description}</div>
								</div>
							</div>
						)}

						</Fragment>
					)
				})}
				</div>
			</div>
		</div>
	)
}
export default withTransaction('ShowForm', 'component')(ShowForm);