import React, { useState } from "react";
import { classnames } from '../../utils/classnames'

import '../partials/styles/Input.css'
import RequiredBadge from '../partials/RequiredBadge'

const CurrencyField = ({
	prefix = "$",
	centsSeparator = ".",
	thousandsSeparator = ",",

	label,
	description,
	name,
	onChange,
	isRequired,
	value,
	readOnly,
	className,
	autoComplete,
	autofocus,
	pattern,
	...rest

}) => {
	// Format the price according to the provided settings
	const formatPrice = (inputValue) => {
		if (!inputValue) return prefix; // Default to the prefix if input is empty

		// Remove non-numeric characters except for digits and the cents separator
		const cleanedValue = inputValue
			.replace(new RegExp(`[^0-9${centsSeparator}]`, "g"), "")
			.replace(centsSeparator, "."); // Normalize the cents separator to a dot

		// Split into whole and fractional parts
		const [whole, fractional] = cleanedValue.split(".");
		// Add thousands separator to the whole part
		const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

		// Reassemble the price with the custom cents separator
		const formattedPrice =
		fractional !== undefined
			? `${formattedWhole}${centsSeparator}${fractional.substring(0, 2)}`
			: formattedWhole;

		return `${prefix}${formattedPrice}`;
	};

	const handleInputChange = (e) => {
		const rawValue = e.target.value.replace(prefix, ""); // Remove prefix for clean processing
		const formattedValue = formatPrice(rawValue);
		onChange({
			target: {
				type: 'text',
				name: name.toString(),
				value: formattedValue
			}
		}); // Pass the formatted value to the parent
	};

	if ( !readOnly ) {
		return (
			<>
				{label && (
					<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
				)}
				{description && (
					<div className="input--description">{description}</div>
				)}
				<input
					className={classnames('form-control', className)}
					autoComplete={autoComplete}
					autoFocus={autofocus}
					name={name}
					onChange={handleInputChange}
					pattern={pattern}
					placeholder={`${prefix}0`}
					type="text"
					value={value}
					required={isRequired}
					{...rest}
				/>
				<div className="invalid-feedback" />
			</>
		)
	} else {
		return (
			<>
				<label>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
				<span>{value}</span>
			</>
		)
	}
};
export default CurrencyField;

/*

***** Usage *****

const App = () => {
  const [price, setPrice] = useState("");

  return (
    <div>
      <h1>Price Formatter</h1>
      <PriceInput
        value={price}
        onChange={setPrice}
        prefix="$"
        centsSeparator="."
        thousandsSeparator=","
      />
      <p>Formatted Price: {price}</p>
    </div>
  );
};
*/

