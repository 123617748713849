import React from 'react'

export default function RequiredBadge({value, count = null}) {
    if(value) {
        return <span className="required-field-completed">
            <i className="fa fa-check fa-sm" />
        </span>
    }

    return <span className="required-field">Required{count ? ` (${count})` : ''}</span>

}