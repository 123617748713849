import React, { Component } from 'react'
import { useHistory } from 'react-router-dom';
import SortableTable from '../partials/SortableTable'

/* ************************************************************************** */
export const JobList = ({ status, jobs, isFetching, error, options }) => {
  
	const history = useHistory();

	if (!isFetching && jobs.length === 0) 
		return <span className="d-inline-block m-3 p-3 border rounded bg-light">No jobs found</span>
	
	let { list_count, column_choice, reference_label } = options

	// All possible columns that can be displayed
	let attachment = {name: '', value: 'attachments', custom:(attachments) => {
		if ( attachments.length > 0) {
			return <i className="far fa-file" title={attachments.length + ' file' + (attachments.length > 1 ? 's' : '')}></i>
		} else {
			return ('')
		}
	}};
	let link = {name: '', value: 'histories', custom:(links) => {
		if ( links.length > 0) {
			return <i className="fa fa-link" title={links.length + ' link' + (links.length > 1 ? 's' : '')}></i>
		} else {
			return ('')
		}
	}};
	let notes = {name: '', value: 'notes', custom:(notes) => {
		if ( notes.length > 0) {
			return <i className="fa fa-clipboard" title={notes.length + ' note' + (notes.length > 1 ? 's' : '')}></i>
		} else {
			return ('')
		}
	}}

	let name = {name: 'Name', value: 'name'};
	let status_header = {name: 'Status', value: 'status' };
	let reference_no = {name: reference_label, value: 'reference_no'};
	let ols_direct_no = {name: 'OLS Direct Number', value: 'directNo', custom:(directNo) => (
		<a href={window.ols_url + `onlineservices/construction/account_management/?direct_no=${directNo}`} target="_blank">
			{directNo}
		</a>
	)};
	let progress = {value: 'progress', name: 'Progress', custom:(progress) => (
		<div className="progress-bar-container" style={{ verticalAlign: 'middle' }}>
			<div className="progress-bar" style={{ width: progress+'%' }} />
		</div>
	)};
	
	// user-configurable column
	let column = {}
	if ( column_choice == 'location' ) {
		column = { value: 'location_desc', name: 'Location' }
	} else {
		column = { value: 'form_variant', name: 'Form Logo', sort: false, custom:(form_variant) => {
			return (form_variant != null ? form_variant.title : '')
		}}
	}

	let entry_user = {name: 'Entry User', value: 'user', custom:(user) => (
		user?.name ?? "Guest"
	)};
	let created_at = {name: 'Created', value: 'created_at', date: true };
	let review_at = {name: 'Marked for Review', value: 'review_at', date: true };
	let archived_at = {name: 'Archived On', value: 'updated_at', date: true };
	let imported_by = {name: 'Imported By', value: 'imported_by' };
	let imported_at = {name: 'Date Imported', value: 'imported_at', date: true};

	// Compile which columns to display, depending on the job list you're viewing
	let header_list = [];
	switch( status ) {
		case "open":
			header_list.push(attachment, link, notes, name, reference_no, progress, column, entry_user, created_at);
			break;
		case "pending":
			header_list.push(attachment, link, notes, name, reference_no, progress, column, entry_user, review_at);
			break;
		case "submitted":
			header_list.push(attachment, link, notes, name, ols_direct_no, reference_no, column, imported_by, imported_at);
			break;
		case "archived":
			header_list.push(attachment, link, notes, name, reference_no, progress, column, entry_user, archived_at);
			break;
		case "search":
			header_list.push(attachment, link, notes, name, status_header, reference_no, progress, column, entry_user, created_at);
			break;
	}

	const viewJob = (job) => {
		const { id } = job;
		history.push(`/job/${id}`)
	}
	
	const rowProps = (row) => ({
		onClick: viewJob.bind(this, row),
		style: { cursor: 'pointer' }
	})

	return (
		<SortableTable
			headers={[ ...header_list ]}
			startSort={status !== 'search' ? 'name' : ''}
			info={jobs}
			view={list_count}
			rowProps={rowProps}
			// all={jobs.length > list_count ? false : true}
		/>
	)
}