import React, { Component, useContext, useEffect, useState, useCallback } from 'react'
import { Button } from 'reactstrap'

import { classnames } from '../../utils/classnames'
import { Form, Checkbox, Input, Textarea } from '../partials'
import { ListDropDown } from '../partials/ListDropDown'
import {
  updateFormData
} from '../../api'

import { FieldChoices } from './FieldChoices'
import { JobAppContext } from '../../GlobalState'

import Place from '../partials/Place'

const default_addr_form = [
	{ seq: '0', type: 'text',	  title: 'Your Customer', 	required: 1 },
	{ seq: '1', type: 'text',	  title: 'Contact Name', 	required: 0 },
	{ seq: '2', type: 'phone',	  title: 'Phone', 			required: 0 },
	{ seq: '3', type: 'text',	  title: 'Street Address', 	required: 1 },
	{ seq: '4', type: 'text', 	  title: 'Street Address', 	required: 0 },
	{ seq: '5', type: 'text', 	  title: 'City', 			required: 1 },
	{ seq: '6', type: 'dropdown', title: 'State', 			required: 0 },
	{ seq: '7', type: 'text',	  title: 'Zip', 			required: 1 },
	{ seq: '8', type: 'text',	  title: 'County', 			required: 0 },
	{ seq: '9', type: 'email',	  title: 'Email Address', 	required: 0 }
]

const defaultStyle = {
	layout: "default", // default | columns
	addr_plus: false,
	compact: false,
	fieldset: false
}

export default function Field(props) {

	const { mapped_types, address_types } = useContext(JobAppContext);

	const choices = props.field.choices || ''

	const [ isEditing, setIsEditing ] = useState(props.field.isEditing ? true : false);
	const [ newFld, setNewFld ] = useState(props.field.new || false);
	const [ backup, setBackup ] = useState(props.field);

	const [ deletedChoices, setDeletedChoices ] = useState([]);
	
	const [ field, setField ] = useState({
		...props.field,
		choices: [...choices]
	});

	const [ addressModal, setAddressModal ] = useState(false);
	const [ newStyle, setNewStyle ] = useState(defaultStyle);

	useEffect(() => {
		if ( props.field?.style ) {
			// append found styles to base (default) set of "address block" styles
			try {
				var setStyle = JSON.parse(props.field?.style);
				setNewStyle(style => ({
					...style,
					...setStyle
				}))
			} catch (e) {
				console.log('error', e)
			}
		}
	}, [])
	

	const handleStyleChange = event => {
		const { target } = event
		const { name } = target
		const value = target.type === 'checkbox' ? target.checked : target.value

		// update temporary (display) style settings (what drives the preview)
		setNewStyle(style => ({ ...style, [name]: value }))

		let oStyle;
		try {
			oStyle = JSON.parse(field.style) ?? {}
		} catch (e) {
			oStyle = {}
		}
		oStyle[name] = value;

		// apply style to currently stored field settings (used for save)
		setField(field => ({ ...field, style: JSON.stringify(oStyle)}))
	}

	const changeLayout = (type) => {
		
		setNewStyle(style => ({ ...style, layout: type }))
		
		let oStyle;
		try {
			oStyle = JSON.parse(field.style) ?? {}
		} catch (e) {
			oStyle = {};
		}
		oStyle['layout'] = type;

		setField(field => ({ ...field, style: JSON.stringify(oStyle) }))
	}

  	const handleChange = event => {
		const { target } = event
		const { name } = target
		const value = target.type === 'checkbox' ? target.checked : target.value

		setField({
			...field,
			[name]: value
		})
	}

	const addChoice = name => {
		setField({
			...field,
			choices: [
				...field.choices,
				{
					name: name,
					id: 'key_' + (field.choices.length + 1),
				},
			],
		})
	}

  	const handleSubmit = () => {
		setNewFld(false);
		setIsEditing(false);
		setBackup(field);

		updateFormData({
			type: 'field',
			field: field,
			form_id: props.formId,
			deletedChoices: deletedChoices,
		}).then(response => {
			// setField({ ...response })
			setDeletedChoices([])
		})
	}

	const cancelEdit = () => {
		if (newFld === true) {
			props.removeField(field)
		} else {
			setIsEditing(false);
			setField(backup);
		}
	}

	const handleRemoveField = () => {
		if (newFld === false) {
			if ( confirm('Are you sure you want to delete this field?') ) {
				updateFormData({
					type: 'delete_field',
					field: field,
					form_id: props.formId,
				})
				props.removeField(field)
			}
		} else {
			props.removeField(field)
		}
	}

	const toggleEdit = () => {
		setIsEditing(!isEditing);
		setField(backup);
	}

	const handleRemoveChoice = data => {
		setField({
			...field,
			choices: field.choices.filter(
				(choice, index) => data.id !== choice.id
			),
		});
		setDeletedChoices([
			...deletedChoices, 
			data.id
		]);
	}

	const { index, onDragOver, onDragStart, onDrop, onDragLeave } = props

	const selected_type = (type, type_id) => {
		if ( type == 'address' && type_id != null && type_id > 0 ) {
			
			if ( type_id == 2 ) { // project location identified by value
				var found_type = address_types.find(({ value }) => value === type_id);
			} else {
				var found_type = address_types.find(({ id }) => id === type_id);
			}
			return found_type?.id ?? 0;
			
		} else {
			return type_id;
		}
	}

	let disableField = (field.type != 'instructions' && !field.title.length) || 
						(field.type == 'instructions' && !field.description.length)

	const printForm = () => {
		<ul>
			{default_addr_form.map(fld => (
				<li>{fld.title}</li>
			))}
		</ul>
	}

	return (
		<div
			className={'field' + (isEditing ? ' editing border border-danger' : '')}
			// draggable
			// onDragOver={onDragOver}
			// onDragLeave={onDragLeave}
			// onDragStart={onDragStart}
			// onDrop={onDrop}
			id={props.id}
		>
			{field.type == 'instructions' ? (
				<>
					{isEditing ? (
						<>
							<label htmlFor='description'>Display Text <span className="required">Required</span>{' '}</label>
							<textarea
								rows={3}
								name="description"
								value={field.description}
								className="form-control"
								onChange={handleChange} />
						</>
					) : (
						<div className="staticTextField">
							{field.description ?? ''}
						</div>
					)}
				</>
			) : (
				<div style={{ flexGrow: 1 }}>
					<div className="field_data">
						
						<div className="fieldType">
							<span className="badge badge-secondary text-white">
								{field.type}
							</span>
						</div>

						<div className="field_title">
							{isEditing ? (
								<Input
									autoComplete="off"
									autoFocus={true}
									onChange={handleChange}
									name="title"
									placeholder="Field Title"
									value={field.title}
								/>
							) : (
								<div className="fieldTitleInline">
									<span>name</span>
									{ field.title || backup.title || 'Field Title' }
								</div>
							)}
						</div>

						{field.type !== 'hidden' && (
							<div className="fieldType">
								{isEditing ? (
									<Checkbox
										label="Required"
										name="required"
										value={field.required}
										onChange={handleChange}
									/>
								) : (
									<>
										<span>Required</span>
										<div className="fieldTitleInline">
											{field.required ? 'yes' : 'no'}
										</div>
									</>
								)}
							</div>
						)}

						{field.type == 'text' && field.type_id == null && (
							<div className="fieldType">
								{isEditing ? (
									<>
										<Checkbox
											label="Import with Notes"
											name="add_to_notes"
											value={field.add_to_notes}
											onChange={handleChange}
										/>									
									</>
								) : (
									<>
										<span>Import with Notes</span>
										<div className="fieldTitleInline">
											{field.add_to_notes == 1 ? 'yes' : 'no'}
										</div>
									</>
								)}
							</div>
						)}

						{(field.type == 'address' || field.type == 'address_plus') && (
							<div className="fieldType">
								{isEditing && !addressModal && (
									<span role="button" style={{ color: '#000000' }} onClick={(e) => { e.stopPropagation(); setAddressModal(addr => !addr) }}>
										<i className="fa fa-cog mx-2"></i>
										Customize
									</span>
								)}
							</div>
						)}

						{/* field.type === 'hidden' && (
							<div className="field_title">
								{!isEditing && (
									<div className="fieldType">
										<span>Value</span>
										{field.defaultValue}
									</div>
								)}
							</div>
						) */}
					
					</div>

					{/* isEditing && field.type === 'hidden' && (
						<Input
							autoComplete="off"
							className="defaultValue"
							onChange={handleChange}
							name="defaultValue"
							placeholder="Default Value"
							value={field.defaultValue || ''}
						/>
					) */}

					{isEditing && !addressModal && (
						<>
							<Input
								autoComplete="off"
								className="description"
								onChange={handleChange}
								name="description"
								placeholder="Description"
								value={field.description || ''}
							/>
							<ListDropDown
								label="Type ID"
								description='Required for "mapped" and "address" fields'
								className="form-group input-wrapper"
								name="type_id"
								selected={selected_type(field.type, field?.type_id ?? 0)}
								onChange={handleChange}
								items={field.type == 'address' || field.type == 'address_plus' ? address_types : mapped_types}
							/>
							{(field.type === 'choice' || field.type === 'assoc_choice') && (
								<FieldChoices
									choices={field.choices}
									addChoice={addChoice}
									removeChoice={handleRemoveChoice}
								/>
							)}
						</>
					)}

					{isEditing && addressModal && (
						<div className="row p-3 mt-3">
							<div className="col-4">
								<h5 className="border-bottom pb-3 my-3">Customize Address Form Layout</h5>
								<ul className="list-unstyled d-flex align-items-center">
									<li className={"mr-3 p-3 border rounded-pill" + (newStyle.layout == 'default' ? ' border-dark' : ' border-white list-link')}
										onClick={() => changeLayout("default")}>Default</li>
									<li className={"p-3 border rounded-pill" + (newStyle.layout == 'columns' ? ' border-dark' : ' border-white list-link')}
										onClick={() => changeLayout("columns")}>Columns</li>
								</ul>
								<div>
									<input type="checkbox" name="addr_plus" value="Y" checked={newStyle?.addr_plus} onChange={handleStyleChange} /> Lot, Parcel, &amp; Block<br />
									<input type="checkbox" name="compact" value="Y" checked={newStyle?.compact} onChange={handleStyleChange} /> Compact<br />
									<input type="checkbox" name="fieldset" value="Y" checked={newStyle?.fieldset} onChange={handleStyleChange} /> Fieldset<br />
								</div>
								<div className="mt-3">
									<button type="button" className="btn btn-primary" onClick={handleSubmit}>Save</button>
									<button type="button" className="btn btn-secondary ml-3" onClick={(e) => { e.stopPropagation(); setNewStyle(defaultStyle); setAddressModal(addr => !addr) }}>Cancel</button>
								</div>
							</div>
							<div className="col-8">
								<h5 className="border-bottom pb-3 my-3">Preview</h5>
								<Place
									fldType={field.type}
									description={field.description}
									hasContact={field.type_id != 2 ? true : false}
									isRequired={field.required}
									label={field.title + ' '}
									onChange={handleChange}
									parent={field.id}
									type_id={field.type_id}
									allow_none={false}
									readOnly={false}
									fields={field.field_configs}
									style={newStyle}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			{isEditing ? (
				<>
				{!addressModal && (
					<Form className={classnames('fieldOptions')} onSubmit={e => e.preventDefault()}>
						<Button
							color="primary"
							onClick={handleSubmit}
							disabled={disableField}
							type="submit"
						>
							Save
						</Button>
						<Button onClick={cancelEdit}>
							Cancel
						</Button>
					</Form>
				)}
				</>
			) : (
				<div className="editButtons">
					<span className="fa fa-pencil-alt mr-3" onClick={toggleEdit} style={{ cursor: 'pointer' }} />
					<span className="fa fa-trash" onClick={handleRemoveField} style={{ cursor: 'pointer' }} />
				</div>	
			)}
		</div>
	)
}

