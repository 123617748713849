import React, { useState, useEffect, useContext } from "react";
import GoogleMapsAutocomplete from "./GoogleMapsAutocomplete";
import { Input } from "./Input";
import { Select } from "./Select";
import { JobAppContext } from "../../GlobalState";
import ParcelSearchModal from "./ParcelSearchModal";
import RequiredBadge from "./RequiredBadge";
import { Collapse } from "react-bootstrap";
import { validateAddress, validateOwner } from "../parcelmaps/utils";

export default function ProjectLocation({
  projectFieldId,
  value,
  onChange,
  isRequired,
  updateAnswers,
  currentParcel,
  currentParcelApi,
  fldType,
  type_id,
  readOnly,
  style,
  hasCounty = true,
  owner,
  effectiveOwnerId
}) {
  const isAddressFilled =
    !!(value?.address?.address ||
    value?.address?.address_2 ||
    value?.address?.city ||
    value?.address?.state ||
    value?.address?.zip);
  const { states } = useContext(JobAppContext);
  const [modal, setModal] = useState(false);
  const [showInputs, setShowInputs] = useState(isAddressFilled);

  useEffect(() => {
    if(isAddressFilled && !showInputs) setShowInputs(true)
  }, [isAddressFilled]);

  const [searchValue, setSearchValue] = useState("");
  const [address, setAddress] = useState({});
  const [initialParcelSearch, setInitialParcelSearch] = useState(null);

  // Validation
  const addressValidation = validateAddress(
    value?.address,
    currentParcel?.address
  );
  const isAddressMatch = Object.values(addressValidation).every((v) => v);

  const ownerValidation = validateOwner(owner?.address, currentParcel?.owner);
  const isOwnerMatch = Object.values(ownerValidation).every((v) => v);

  const [parcelCardOpen, setParcelCardOpen] = useState(false);
  const shouldShowParcelCard =
    currentParcelApi.isFetching || currentParcelApi.isFetched;

	useEffect(() => {
    	setParcelCardOpen(!isOwnerMatch || !isAddressMatch);
  	}, [isOwnerMatch, isAddressMatch]);

  if (readOnly)
    return (
      <ProjectLocationReadOnly
        value={value}
        style={style}
        isRequired={isRequired}
      />
    );

  return (
    <div className="mb-4 mt-3">
      {!showInputs ? (
        <div className="form-group">
          <label className="">
            Project Location
            <RequiredBadge value={value.address.address} count={4} />
          </label>
          <div className="d-flex gap-2 align-items-center">
            <ParcelSearchIcon className="ml-1 mr-3" />
            <div className="flex-grow-1">
              <GoogleMapsAutocomplete
                className="rounded-pill bg-white shadow-sm"
                placeholder="Lookup parcel and owner information by address..."
                onChange={(e) => setSearchValue(e.target.value)}
                isRequired={isRequired}
				onSelect={(address) => {
                  const fullAddress = [
                    address.address,
                    address.city,
                    address.state,
                    address.zip,
                  ]
                    .filter(Boolean)
                    .join(" ");
                  setSearchValue("");
                  setInitialParcelSearch(fullAddress);
                  setModal(true);
                }}
                value={searchValue}
              />
            </div>
            <div className="px-3 text-muted">or</div>
            <div className="">
              <button
                type="button"
                className="btn btn-light btn-sm border text-muted w-100 px-4 rounded-pill"
                onClick={() => setShowInputs(true)}
              >
                Enter Address Manually
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="form-group">
          <>
            <div className="form-row">
              <div className="col">
                <div className="d-flex align-items-center gap-2">
                  <label className="my-0">
                    Street Address
                    <RequiredBadge value={value.address.address} />
                  </label>

                  <button
                    type="button"
                    className="btn btn-link btn-sm text-muted ml-auto"
                    onClick={() => {
                      const fullAddress = [
                        value.address.address,
                        value.address.city,
                        value.address.state,
                        value.address.zip,
                      ]
                        .filter(Boolean)
                        .join(" ");
                      setInitialParcelSearch(fullAddress);
                      setModal(true);
                    }}
                  >
                    <ParcelSearchIcon
                      variant="muted"
                      subIcon="mouse-pointer"
                      className="mr-2"
                      style={{
                        transform: "scale(0.8)",
                      }}
                    />
                    Pick parcel from map
                  </button>
                </div>
                <GoogleMapsAutocomplete
                  name={projectFieldId + ".address.address"}
                  onChange={onChange}
                  onSelect={(address) => {
                    updateAnswers({
                      [projectFieldId]: { address },
                    });
                  }}
                  value={value.address.address}
                  isRequired={isRequired}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col">
                <Input
                  label="Street Address 2"
                  name={projectFieldId + ".address.address_2"}
                  onChange={onChange}
                  type="text"
                  isRequired={false}
                  placeholder=""
                  value={value?.address?.address_2}
                />
              </div>
            </div>

            {(fldType === "address_plus" || style?.addr_plus) && (
              <>
                <div className="form-row">
                  <div className="col">
                    <Input
                      label="Parcel Number"
                      name={projectFieldId + ".address.parcel_no"}
                      onChange={onChange}
                      type="text"
                      isRequired={false}
                      value={value?.address?.parcel_no}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-6">
                    <Input
                      label="Lot Number"
                      name={projectFieldId + ".address.lot_no"}
                      onChange={onChange}
                      type="text"
                      isRequired={false}
                      value={value?.address?.lot_no}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      label="Block Number"
                      name={projectFieldId + ".address.block_no"}
                      onChange={onChange}
                      isRequired={false}
                      placeholder=""
                      value={value?.address?.block_no}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="form-row">
              <div className="col-md-4">
                <Input
                  label="City"
                  name={projectFieldId + ".address.city"}
                  onChange={onChange}
                  type="text"
                  isRequired={isRequired}
                  value={value?.address?.city}
                />
              </div>
              <div className="col-md-2">
                <Select
                  label="State"
                  onChange={onChange}
                  name={projectFieldId + ".address.state"}
                  isRequired={isRequired}
                  value={value?.address?.state}
                  children={states.map((state, index) => (
                    <option key={index} value={state.state_code}>
                      {state.state_desc}
                    </option>
                  ))}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Zip"
                  name={projectFieldId + ".address.zip"}
                  isRequired={isRequired}
                  onChange={onChange}
                  type="text"
                  value={value?.address?.zip}
                />
              </div>
              {hasCounty === true && (
                <div className="col-md-3">
                  <Input
                    label="County"
                    name={projectFieldId + ".address.county"}
                    isRequired={false}
                    onChange={onChange}
                    type="text"
                    value={value?.address?.county}
                  />
                </div>
              )}
            </div>
          </>
          {shouldShowParcelCard && (
            <div className="position-relative">
              <button
                type="button"
                className={`btn shadow-sharp position-absolute fw-bold px-2 py-1 rounded-pill bg-white text-info transition`}
                style={{
                  top: -13,
                  zIndex: 1,
                  marginLeft: parcelCardOpen ? "1rem" : 0,
                  fontSize: 12,
                }}
                onClick={() => setParcelCardOpen(!parcelCardOpen)}
              >
                {currentParcelApi.isFetching ? (
                  <>
                    <i className="fa fa-spinner fa-spin mr-2" />
                    Searching
                  </>
                ) : currentParcel ? (
                  <>
                    {isOwnerMatch && isAddressMatch ? (
                      <>
                        <i className="fa fa-check-circle mr-2" />
                        Parcel information applied
                      </>
                    ) : (
                      <>
                        <i className="fa fa-info-circle mr-2" />
                        We found this information
                      </>
                    )}
                    <i
                      className={`fa fa-chevron-up ml-2 fa-xs transition ${
                        parcelCardOpen ? "fa-rotate-180" : ""
                      }`}
                    />
                  </>
                ) : (
                  <>
                    <i className="fa fa-info-circle mr-2" />
                    No parcel found for this address
                  </>
                )}
              </button>
              {currentParcel && parcelCardOpen && (
                <button
                  type="button"
                  className="shadow-sharp position-absolute text-info fw-bold px-2 py-1 rounded-pill bg-white mr-3 border-0 btn-sm"
                  style={{ top: -12, right: 0, zIndex: 1, fontSize: 11 }}
                  onClick={() => {
                    setInitialParcelSearch(
                      `${value.address.address}, ${value.address.city}, ${value.address.state} ${value.address.zip}`
                    );
                    setModal(true);
                  }}
                >
                  <i className="fa fa-map-marker-alt mr-2 fa-xs" />
                  View parcel on map
                </button>
              )}
              <div style={{ marginTop: 25, minHeight: 10 }}>
                <Collapse in={parcelCardOpen}>
                  <div
                    className={`position-relative ${
                      parcelCardOpen ? "show" : ""
                    }`}
                  >
                    <div
                      className={`card mx-0 rounded-xl shadow-simple overflow-hidden border-info bg-light`}
                      style={{ border: "1.5px solid" }}
                    >
                      {/* <small className="bg-light border-bottom d-flex align-items-center fw-bold px-2 py-1 text-dark">
                      {currentParcelApi.isFetching ? (
                        <>
                          <i className="fa fa-spinner fa-spin text-muted mr-2" />
                          Searching for parcel
                        </>
                      ) : currentParcel ? (
                        <>
                          <i className="fa fa-check text-success mr-2" />
                          Parcel found at this address
                          <button
                            type="button"
                            className="btn btn-link btn-sm text-muted ml-auto"
                            onClick={() => {
                              setInitialParcelSearch(
                                `${value.address.address}, ${value.address.city}, ${value.address.state} ${value.address.zip}`
                              );
                              setModal(true);
                            }}
                          >
                            View parcel on map
                            <i className="fa fa-arrow-right ml-2 fa-xs" />
                          </button>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-times text-danger mr-2" />
                          No parcel found for this address
                        </>
                      )}
                    </small> */}
                      <div className="d-flex">
                        {currentParcelApi.isFetching ? (
                          <div className="text-muted p-2">Verifying parcel</div>
                        ) : currentParcel ? (
                          <>
                            <div className="w-50 text-muted border-right d-flex flex-column pt-3 pb-2">
                              <div className="px-3 py-2">
                                <div className="fs-6 text-dark fw-bold d-flex align-items-center">
                                  <span
                                    style={{
                                      opacity: isAddressMatch ? 0.8 : 1,
                                    }}
                                  >
                                    Parcel Address
                                  </span>
                                  {isAddressMatch ? (
                                    <span className="badge text-info ml-3">
                                      <i className="fa fa-check-circle mr-1" />
                                      Address applied
                                    </span>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-link btn-sm text-info fw-bold"
                                      style={{
                                        fontSize: 11,
                                      }}
                                      disabled={isAddressMatch}
                                      onClick={() => {
                                        updateAnswers({
                                          [projectFieldId]: {
                                            address: currentParcel?.address,
                                          },
                                        });
                                      }}
                                    >
                                      Use this address
                                      <i className="fa fa-arrow-right ml-1 fa-xs" />
                                    </button>
                                  )}
                                </div>
                                <div
                                  style={{ opacity: isAddressMatch ? 0.8 : 1 }}
                                >
                                  <div
                                    className={
                                      !addressValidation?.address
                                        ? "fw-bold"
                                        : null
                                    }
                                  >
                                    {currentParcel?.address?.address}
                                  </div>
                                  <span
                                    className={
                                      !addressValidation?.city
                                        ? "fw-bold"
                                        : null
                                    }
                                  >
                                    {currentParcel?.address?.city}
                                  </span>{" "}
                                  <span
                                    className={
                                      !addressValidation?.state
                                        ? "fw-bold"
                                        : null
                                    }
                                  >
                                    {currentParcel?.address?.state}
                                  </span>{" "}
                                  <span
                                    className={
                                      !addressValidation?.zip ? "fw-bold" : null
                                    }
                                  >
                                    {currentParcel?.address?.zip}
                                  </span>
                                  {currentParcel?.address?.county && (
                                    <div
                                      className={
                                        !addressValidation?.county
                                          ? "fw-bold"
                                          : null
                                      }
                                    >
                                      {currentParcel?.address?.county}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="w-50 text-muted d-flex flex-column pt-3 pb-2">
                              <div className="px-3 py-2">
                                <div className="fs-6 text-dark fw-bold">
                                  <span
                                    style={{ opacity: isOwnerMatch ? 0.8 : 1 }}
                                  >
                                    Owner Information
                                  </span>
                                  {isOwnerMatch ? (
                                    <span className="badge text-info ml-3">
                                      <i className="fa fa-check-circle mr-1" />
                                      Owner applied
                                    </span>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-link btn-sm text-info fw-bold"
                                      style={{
                                        fontSize: 11,
                                      }}
                                      disabled={isOwnerMatch}
                                      onClick={() => {
                                        updateAnswers({
                                          [effectiveOwnerId]: {
                                            address: {
                                              company:
                                                currentParcel?.owner?.name,
                                              address:
                                                currentParcel?.owner?.address,
                                              city: currentParcel?.owner?.city,
                                              state:
                                                currentParcel?.owner?.state,
                                              zip: currentParcel?.owner?.zip,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      Use this owner
                                      <i className="fa fa-arrow-right ml-1 fa-xs" />
                                    </button>
                                  )}
                                </div>
                                <div
                                  style={{ opacity: isOwnerMatch ? 0.8 : 1 }}
                                >
                                  <div
                                    className={
                                      !ownerValidation?.name ? "fw-bold" : null
                                    }
                                  >
                                    {currentParcel?.owner?.name}
                                  </div>
                                  <div
                                    className={
                                      !ownerValidation?.address
                                        ? "fw-bold"
                                        : null
                                    }
                                  >
                                    {currentParcel?.owner?.address}
                                  </div>
                                  <span
                                    className={
                                      !ownerValidation?.city ? "fw-bold" : null
                                    }
                                  >
                                    {currentParcel?.owner?.city}
                                  </span>{" "}
                                  {currentParcel?.owner?.city &&
                                    currentParcel?.owner?.state && <>, </>}
                                  <span
                                    className={
                                      !ownerValidation?.state ? "fw-bold" : null
                                    }
                                  >
                                    {currentParcel?.owner?.state}
                                  </span>{" "}
                                  <span
                                    className={
                                      !ownerValidation?.zip ? "fw-bold" : null
                                    }
                                  >
                                    {currentParcel?.owner?.zip}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="text-muted p-2">No parcel found</div>
                        )}
                      </div>
                    </div>
                    {currentParcel && (!isAddressMatch || !isOwnerMatch) && (
                      <div
                        className="position-absolute d-flex align-items-center px-3 w-100"
                        style={{
                          bottom: -10,
                          zIndex: 1,
                        }}
                      >
                        <button
                          type="button"
                          className="shadow-sharp text-info fw-bold px-2 py-1 rounded-pill bg-white border-0 btn-sm ml-auto"
                          onClick={() => setParcelCardOpen(false)}
                          style={{
                            fontSize: 11,
                          }}
                        >
                          Dismiss
                        </button>
                        <button
                          type="button"
                          className="shadow-sharp fw-bold btn-info px-2 py-1 rounded-pill border-0 btn-sm ml-2"
                          onClick={() => {
                            updateAnswers({
                              [projectFieldId]: {
                                address: currentParcel?.address,
                              },
                              [effectiveOwnerId]: {
                                address: {
                                  company: currentParcel?.owner?.name,
                                  address: currentParcel?.owner?.address,
                                  city: currentParcel?.owner?.city,
                                  state: currentParcel?.owner?.state,
                                  zip: currentParcel?.owner?.zip,
                                },
                              },
                            });
                          }}
                          style={{
                            fontSize: 11,
                          }}
                        >
                          Apply this information
                          <i className="fa fa-arrow-right ml-2 fa-xs" />
                        </button>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
          )}
        </div>
      )}
      {modal && (
        <ParcelSearchModal
          searchValue={initialParcelSearch}
          isOpen={modal}
          toggle={() => setModal(!modal)}
          currentAddress={value}
          currentOwner={owner}
          onChange={({ address, owner }) => {
            updateAnswers({
              [projectFieldId]: {
                address: address,
              },
              [effectiveOwnerId]: {
                address: {
                  company: owner?.name,
                  address: owner?.address,
                  city: owner?.city,
                  state: owner?.state,
                  zip: owner?.zip,
                },
              },
            });
            setShowInputs(true);
          }}
        />
      )}
    </div>
  );
}

function ProjectLocationReadOnly({ value, style, isRequired }) {
  var addr = value["address"];
  if (
    addr.company == "" &&
    addr.name == "" &&
    addr.phone == "" &&
    addr.address == "" &&
    addr.address_2 == "" &&
    addr.city == "" &&
    addr.state == "" &&
    addr.zip == "" &&
    addr.email == ""
  ) {
    return <div>No Address Data Found</div>;
  } else {
    // build address display
    let address = [];
    if (addr.company != "") address.push(addr.company);
    if (addr.name != "") address.push(addr.name);
    if (addr.phone != "") address.push(addr.phone);
    if (addr.address != "") address.push(addr.address);
    if (addr.address_2 != "") address.push(addr.address_2);
    if (addr.city != "" && addr.state != "" && addr.zip != "") {
      address.push(addr.city + ", " + addr.state + " " + addr.zip);
    } else {
      if (addr.city != "") address.push(addr.city);
      if (addr.state != "") address.push(addr.state);
      if (addr.zip != "") {
        address.push(addr.zip);
      }
    }
    if (addr.county != "") {
      address.push(addr.county);
    }
    if (addr.email != "") {
      address.push(addr.email);
    }

    return (
      <div className="form-group">
        <fieldset className={style?.fieldset ? "show" : "hide"}>
          <legend className={style?.fieldset ? "show" : "hide"}>
            Project Location
          </legend>
          {addr?.bond_no && (
            <div className="form-row">
              <div className="col">
                <label>Bond Number </label>
                <span>{addr?.bond_no}</span>
              </div>
            </div>
          )}
          <div className="form-row">
            <div className="col">
              <label style={{ verticalAlign: "top" }}>
                Address{" "}
                {isRequired === 1 && (
                  <RequiredBadge value={address?.length > 0} />
                )}{" "}
              </label>
              <span style={{ display: "inline-block", whiteSpace: "pre-wrap" }}>
                {address.map((addr_field) => addr_field + "\n")}
              </span>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export function ParcelSearchIcon({
  baseIconColor = "muted",
  subIconColor = "muted",
  subIcon = "search",
  className = "",
  ...props
}) {
  return (
    <span
      className={`position-relative d-inline-block ${className}`}
      {...props}
    >
      <i
        className={`fa fa-map text-${baseIconColor}`}
        style={{ opacity: 0.75 }}
      />
      <i
        className={`fa fa-${subIcon} fa-xs position-absolute text-${subIconColor}`}
        style={{
          bottom: -2,
          right: -6,
          filter: `
            drop-shadow(1px 0 0 white) 
            drop-shadow(-1px 0 0 white) 
            drop-shadow(0 1px 0 white) 
            drop-shadow(0 -1px 0 white)
          `,
          // transform: "rotate(-15deg)",
        }}
      />
    </span>
  );
}
