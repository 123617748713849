import {
    GoogleMap,
    Marker,
    MarkerClusterer,
    useGoogleMap,
    useJsApiLoader,
  } from "@react-google-maps/api";
  import { Button } from "reactstrap";
  import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
  } from "react";
  import ParcelMapScript from "./ParcelMapScript";
  
  const center = { lat: 41.5495, lng: -81.4222 };
  const mapContainerStyle = { height: "100%", width: "100%" };
  const googleMapsApiKey = "AIzaSyBaNQdTgz9e3YVJS3Pt34yM1ap8heNrQiY";
  
  const GoogleMapsCluster = ({
    markers,
    children,
    onParcelSelect,
    defaultZoom,
    showLocationMarkers = true,
  }) => {
    const [markerCoords, setMarkerCoords] = useState();
    const [error, setError] = useState(false);
    const [zoom, setZoom] = useState(defaultZoom);
    const mapRef = useRef();
    const map = mapRef?.current;
  
    const [googleMapLoaded, setGoogleMapLoaded] = useState(false);
  
    useEffect(() => {
      getCoordinates(markers)
        .then((coordinates) => {
          setMarkerCoords(coordinates);
        })
        .catch((error) => {
          setError(true);
          console.error(error);
        });
    }, [markers]);

    useEffect(() => {
      resetBounds(map)
    }, [markerCoords])
  
    const [showResetButton, setShowResetButton] = useState(true);
  
    const enableResetButton = useCallback(() => {
      if (!showResetButton) setShowResetButton(true);
    }, [showResetButton]);
  
    const resetBounds = (map) => {
      setShowResetButton(false);
      const bounds = new window.google.maps.LatLngBounds();
  
      markerCoords?.forEach((marker) => {
        if (marker?.coordinates && !marker?.error)
          bounds.extend(marker.coordinates);
        map?.fitBounds(bounds);
      });
  
      if (defaultZoom) {
        setTimeout(() => {
          map?.setZoom(defaultZoom);
        }, 100);
      }
    };
  
    const onLoad = useCallback(
      (map) => {
        resetBounds(map);
        setGoogleMapLoaded(true);
        mapRef.current = map;
      },
      [markerCoords]
    );
  
    const { isLoaded } = useJsApiLoader({ googleMapsApiKey });
    if (!isLoaded) {
      return null;
    }
  
    if (!markerCoords)
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <i className="fal fa-spinner-third fa-spin fa-6x text-muted d-block" />
        </div>
      );
  
    return (
      <>
        {googleMapLoaded && (
          <ParcelMapScript
            selectedCoords={markerCoords?.[0]?.coordinates}
            map={map}
            onParcelSelect={onParcelSelect}
          />
        )}
        <GoogleMap
          center={center}
          zoom={zoom}
          mapContainerStyle={mapContainerStyle}
          onLoad={onLoad}
          onZoomChanged={enableResetButton}
          onDragStart={enableResetButton}
          options={{
            styles: [],
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: window.google?.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google?.maps.ControlPosition.LEFT_TOP,
            },
            zoomControl: true,
            zoomControlOptions: {
              position: window.google?.maps.ControlPosition.RIGHT_BOTTOM,
            },
            streetViewControl: true,
            streetViewControlOptions: {
              position: window.google?.maps.ControlPosition.RIGHT_TOP,
            },
            fullscreenControl: false,
          }}
        >
          {showLocationMarkers && (
            <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
              {(clusterer) =>
                markerCoords?.map((marker, index) => (
                  <Marker
                    key={index}
                    position={marker?.coordinates}
                    clusterer={clusterer}
                    label={marker?.label}
                    title={marker?.address}
                    onMouseDown={marker?.onMouseDown}
                    onMouseOver={marker?.onMouseOver}
                    onMouseOut={marker?.onMouseOut}
                  />
                ))
              }
            </MarkerClusterer>
          )}
          {children}
          <ResetBoundsControl resetBounds={resetBounds} show={showResetButton} />
        </GoogleMap>
      </>
    );
  };
  
  export const ResetBoundsControl = ({ resetBounds, show }) => {
    const map = useGoogleMap();
    return (
      <MapControl position="RIGHT_BOTTOM">
        {show && (
          <div style={{ marginRight: 2 }}>
            <Button
              color="light"
              onClick={() => resetBounds(map)}
              size="sm"
              title="reset bounds"
              className="me-2 google-maps-button p-1"
            >
              <span className="fa fa-expand" style={{ width: "41px" }} />
            </Button>
          </div>
        )}
      </MapControl>
    );
  };
  
  async function getCoordinates(markers) {
    const coordinates = [];
  
    for (const marker of markers) {
      const formattedAddress = encodeURIComponent(marker?.address);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${googleMapsApiKey}`;
  
      try {
        const response = await fetch(url);
        const data = await response.json();
  
        if (data.status === "OK" && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          coordinates.push({
            address: marker?.address,
            label: marker?.label,
            coordinates: location,
            ...marker,
          });
        } else {
          coordinates.push({
            address: marker,
            error: "No results found",
          });
        }
      } catch (error) {
        coordinates.push({
          address: marker,
          error: "An error occurred",
        });
      }
    }
  
    return coordinates;
  }
  
  export const MapControl = ({ position, children }) => {
    const map = useGoogleMap();
    const ref = useRef();
    useEffect(() => {
      if (map && ref) {
        map.controls[window.google?.maps.ControlPosition[position]].push(
          ref.current
        );
      }
    }, [map, ref]);
    return <div ref={ref}>{children}</div>;
  };
  
  export default GoogleMapsCluster;
  