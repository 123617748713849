import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { JobAppContext } from '../../GlobalState';

import {
	getJob,
	refreshJobHistory,
	createJobNote, deleteJobNote, updateStatus,
	importJob
} from '../../api'

import { classnames } from '../../utils/classnames'
import formatDate from '../partials/formatDate';
// import { can } from '../../utils/can';

import {
	TabContent, TabPane, Nav, NavLink, Table, 
	Card, CardHeader, CardBody, CardText, 
	Row, Col, Button, Badge, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Form
} from 'reactstrap'

import ShareLink from '../partials/ShareLink';
import EmailLinkHistory from '../partials/EmailLinkHistory'
import ShowForm from './ShowForm'
import JobSettings from './JobSettings';
import { useQueryClient } from '@tanstack/react-query';
import { jobKey } from '../hooks';

const tabs = {
	SETTINGS: 'settings',
	EDIT: 'edit',
	SHARE: 'share',
	HISTORY: 'history',
	NOTES: 'notes'
}

const ShowJob = (props) => {

	const queryClient = useQueryClient()

	const { isAuthenticated } = useContext(JobAppContext);	
	
	// tab values
	const [ isLoading, setIsLoading ] = useState(true);
	const [ activeTab, setActiveTab ] = useState(tabs.EDIT);

	const [ isImporting, setImporting ] = useState(false);
	const [ importResult, setImportResult ] = useState(false);
	const [ importMessage, setImportMessage ] = useState('');

	// "edit" tab main job data for top-level info, share, history, and notes tabs
	const [ jobData, setJobData ] = useState({});

	// history tab value
	const [ refreshingHistory, setRefreshingHistory ] = useState(false);

	// Note modal values
	const [ isSubmitting, setSubmitting ] = useState(false);
	const [ modal, setModal ] = useState(false);
	const [ noteText, setNoteText ] = useState('');

	const [ service_type, setServiceType ] = useState(0);
	const [ service_types, setServiceTypes ] = useState([]);

	const history = useHistory();
	const params = useParams();

	useEffect(() => {
		
		const { id } = params;
		getJob(id).then(data => {
			
			if ( data['error'] ) {
				console.log(data['error']);
				history.push('/jobs')
				return;
			
			} else {
			
				var service_type_opts = data.company.service_types.filter(data => data.status == 'active')

				var default_service = 0;
				if ( service_type_opts.length > 0 ) {
					default_service = service_type_opts[0].id
					for(var i=0; i < service_type_opts.length; i++) {
						if  ( service_type_opts[i].default ) {
							default_service = service_type_opts[i].id; break;
						}
					}
				}
				setServiceType(default_service);
				setServiceTypes([
					...service_type_opts
				]);

				setJobData({
					...data
				})
				setIsLoading(false);

			}
		}, error => console.error(error))
		
		// TODO: Get Job Settings here
		
	}, [])

	// called after adding a new email
	const updateEmailList = (newEmail) => {
		setJobData({
			...jobData,
			emails: [
				...jobData.emails,
				newEmail
			]
		});
	}
	
	// called after resending an existing email (ie. increases count displayed next to "status" column value)
	const updateEmail = (existing_email) => {
		let newEmailList = jobData.emails.map(em => {
			if ( em.id === existing_email.id ) {
				return existing_email;
			} else {
				return em;
			}
		});
		setJobData({
			...jobData,
			emails: [
				...newEmailList
			]
		});
	}

	const updateLinkList = (newLink) => {
		setJobData({
			...jobData,
			histories: [
				...jobData.histories,
				newLink
			]
		});
	}

	const changeTab = tab => {
		if ( activeTab !== tab ) {
			setActiveTab(tab);
		}
	}

	const refreshHistory = () => {
		setRefreshingHistory(true);
		refreshJobHistory(jobData.id).then(oResp => {
			setRefreshingHistory(false);
			setJobData({
				...jobData,
				stats: [
					...oResp.stats
				]
			});
		});
	}

	const setHistoryAction = (action) => {
		let desc = '';
		switch (action) {
			// company maintenance updates
			case "export": desc = 'Data Exported'; break;
			
			// job detail actions
			case "view"      : desc = 'Viewed';			  	 break;
			case "add"       : desc = 'Job Added to System'; break;
			case "save_draft": desc = 'Save Draft';			 break;
			case "finalized" : desc = 'Finalized';			 break;
			case "submitted" : desc = 'Submitted';			 break;

			// top-level job actions
			case "update_job"      : desc = 'Save Import Settings';	break;
			case "save_setting"    : desc = 'Save Setting';			break;
			case "clear_user_input": desc = 'Cleared User Input';	break;
			case "status_update"   : desc = 'Status Update';		break;
			case "import"          : desc = 'Job Imported to NCS';	break;

			// Share tab actions
			case "email_sent"   : desc = 'Email Sent';		break;
			case "email_failed" : desc = 'Email Failed';	break;
			case "email_user"   : desc = 'Email User';		break;
			case "gen_link"     : desc = 'Link Generated';	break;
			case "email_resent" : desc = 'Email Resent'; break;
			case "email_opt_in_out": desc = 'Email Reminder OptOut Change'; break;
			
			// Notes tab actions
			case "add_note"   : desc = 'Note Added';	break;
			case "delete_note": desc = 'Note Deleted';	break;

			default: desc = '';
		}
		return desc;
	}

	/* ***************************************************************************************** */

	const toggleModal = () => {
		setModal(!modal);
	}
	const handleTextChange = (e) => {
		const { name, value } = e.target;
		setNoteText(value);
	}
	const handleSave = () => {
		setSubmitting(true);
		const noteData = {
			job_id: parseInt(params.id),
			description: noteText
		}
		// console.log('saving note', noteData)
		createJobNote(noteData).then(data => {

			setJobData({
				...jobData,
				notes: [
					data,
					...jobData.notes
				]
			});
			setSubmitting(false);
			
			// clear text field, to prepare for next link to be generated
			setNoteText('');
			setModal(false);
		})
	}
	const deleteNote = (id) => {
		if ( confirm('Are you sure you want delete this note?') ) {
			deleteJobNote(id).then(oResp => {
				var new_note_list = jobData.notes.filter(note => note.id !== oResp.note.id);
				console.log('data response', oResp)
				if ( oResp.status == "success" ) {
					setJobData({
						...jobData,
						notes: [
							...new_note_list
						]
					});
				}
			})
		}
	}
	const closeModal = () => {
		if ( noteText != '' ) {
			if ( confirm('Would you like to save your note before closing?') ) {
				handleSave();
			}
		} else {
			setModal(false)
		}
	}

	/* ***************************************************************************************** */

	const reopenJob = () => {
		// Update job's status to "open"
		updateStatus({ id: jobData.id, status: 'open' }).then((resp) => {
			
			queryClient.setQueryData(jobKey({ id: jobData.id }), {
				...queryClient.getQueryData(jobKey({ id: jobData.id })),
				status: resp.status,
			});

			setJobData({ ...jobData, status: resp.status })

		})
	}

	const update_service = event => {
		const { target } = event
		const { value } = target
		setServiceType(value);
	}

	const importButton = () => {
		
		// console.log('importing job:', jobData)
		if ( !isImporting ) {
			
			setImporting(true);
			setImportResult(false);
			setImportMessage('');
			
			importJob({ id: jobData.id, service: service_type }).then(data => {
				
				if ( data.error ) {
					console.error(data.error)
					setImportResult(true);
					setImportMessage(data.error);
				} else {
					
					setJobData({
						...jobData,
						status: data.job.status,
						directNo: data.ols_resp.directNo
					});

					queryClient.setQueryData(jobKey({ id: jobData.id }), {
						...queryClient.getQueryData(jobKey({ id: jobData.id })),
						status: data.job.status,
						directNo: data.ols_resp.directNo
					});

					window.scrollTo(0, 0);
				}
				setImporting(false)
			}, error => console.log('promise error', error));

		}
	}
	/* ***************************************************************************************** */

	const handleArchive = () => {
		if ( confirm("Are you sure you would like to Archive this Job?") ) {
			updateStatus({ 'id': jobData.id, 'status': 'archived' }).then((resp) => {
				history.push('/jobs')
			});
		}
	}
	const handleDelete = () => {
		if ( confirm('Are you sure you would like to Delete this Job?\n!! WARNING: This cannot be undone !!') ) {
			updateStatus({ 'id': jobData.id, 'status': 'deleted' }).then((resp) => {
				history.push('/jobs')
			});
		}
	}

	/* ***************************************************************************************** */

	// console.log('jobData', jobData)

	var statusDisplay = "", statusColor = "", statusMessage = "";
	switch (jobData.status) {
		case "open":
				statusDisplay = "Open"; statusColor = "info";
				statusMessage = `Job Created on ${formatDate(jobData.created_at, 'L')}`;
				break;
		case "pending":
				statusDisplay = "Review"; statusColor = "warning";
				statusMessage = `Finalized for Review on ${formatDate(jobData.review_at, 'L')}`;
				break;
		case "submitted": 
				statusDisplay = "Submitted"; statusColor = "success";
				statusMessage = `Job data sent to NCS on ${formatDate(jobData.imported_at, 'L')}`;
				break;
		case "archived": 
				statusDisplay = "Archived"; statusColor = "secondary";
				statusMessage = `Archived on ${formatDate(jobData.updated_at, 'L')}`;
				break;
		case "deleted": 
				statusDisplay = "Deleted"; statusColor = "danger";
				statusMessage = `Deleted on ${formatDate(jobData.updated_at, 'L')}`;
				break;
	}

	return (
		<div>
			
			<Modal isOpen={modal} toggle={toggleModal}>
				<ModalHeader>Add Job Note</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSave}>
						<div>
							<label htmlFor="noteText">Note</label>
							<textarea name="noteText" rows="5" className="form-control" value={noteText} onChange={handleTextChange}></textarea>
						</div>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button className="mr-2" onClick={handleSave} disabled={isSubmitting}>
						{isSubmitting ? "Saving" : "Save"}
					</Button>
					<Button onClick={closeModal} disabled={isSubmitting}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			{/*
			<p className="workflow-step mx-4 mt-2">
				Current Workflow Step: <span style={{ 'fontWeight': 'bold' }}>{curr_workflow_step}</span>
			</p>
			*/}
			
			<div className="page-navbar d-flex justify-content-start">
				<Nav tabs className="border-0 pt-4">
					
					{jobData.status == "open" && (
						<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.SETTINGS) ? 'activeTab' : '' )}
								onClick={() => changeTab(tabs.SETTINGS)}
								disabled={isLoading}>
							<i className="fa fa-cog fa-lg"></i>
						</NavLink>
					)}
					<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.EDIT) ? 'activeTab' : '' )}
							onClick={() => changeTab(tabs.EDIT)}>
						Job Data
					</NavLink>
					{jobData.status != "submitted" && (
						<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.SHARE) ? 'activeTab' : '' )}
								onClick={() => changeTab(tabs.SHARE)}
								disabled={isLoading}>
							Share
						</NavLink>
					)}
					<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.HISTORY) ? 'activeTab' : '' )}
							onClick={() => changeTab(tabs.HISTORY)}
							disabled={isLoading}>
						History
					</NavLink>
					<NavLink className={classnames( 'jobTabs__tab', (activeTab === tabs.NOTES) ? 'activeTab' : '' )}
							onClick={() => changeTab(tabs.NOTES)}
							disabled={isLoading}>
						Notes
						{jobData?.notes?.length > 0 && ( <> ({jobData.notes.length})</>)}
					</NavLink>
				</Nav>
			</div>

			<TabContent activeTab={activeTab} className="padded-content">
				
				{jobData.status == "open" && (
					<TabPane tabId={tabs.SETTINGS}>
						<JobSettings readonly={false} status={jobData?.status} />
					</TabPane>
				)}

				<TabPane tabId={tabs.EDIT}>

					<div className="row edit-section-header">
						<div className="col col-xl-10 p-0">

							<div className="d-flex justify-content-between align-items-center">
								
								<div>
									<Badge pill color={statusColor} className="mr-3 text-white" style={{ fontSize: '1.5rem' }}>
										{statusDisplay}
									</Badge>
									
									{jobData?.status == 'pending' && (
										<Button color="secondary" onClick={reopenJob} className="mr-3">
											Re-open Job
										</Button>
									)}

									{jobData?.directNo && (
										<>
											Direct No: 
											<a href={window.ols_url + `onlineservices/construction/account_management/?direct_no=${jobData?.directNo}`} target="_blank" className="ml-2 mr-3 rounded">
												{jobData?.directNo}
											</a>
										</>
									)}
									{statusMessage}
								</div>

								<div className="text-right flex-fill">
									<div>
										{jobData?.status == 'pending' && (
											<>
												{service_types.length > 1 && (
													<select name="service_type" className="selectInput form-control" onChange={update_service} defaultValue={service_type} style={{ display: 'inline', width: 'auto' }}>
														{service_types.map(service => {
															return (<option value={service.id} key={service.id}>{service.text}</option>)
														})}
													</select>
												)}
												<Button color="primary" className="ml-3" onClick={importButton} disabled={isImporting}>
													{isImporting ? (
														<><i className="fas fa-sync-alt fa-spin"></i> Sending Data...</>
													) : (
														<><i className="far fa-check-circle"></i> Send Data to NCS</>
													)}
												</Button>
											</>
										)}
										<Button color="secondary" className="ml-3" onClick={() => setTimeout(function(){window.print()}, 100)}
											disabled={isLoading}
										>
											<i className="fas fa-print"></i> Print
										</Button>
									</div>
								</div>

							</div>
							
							{/* jobData.status === 'open' && (
								<div id="menu" className="d-flex mt-4 mb-3">
									{file && (
										<img alt="Logo" id="form-logo" src={`${window.app_api}/${file.location}`} /> 
									)}
									<div className="flex-grow-1">
										<h3 className="Title">
											{jobData.name}
										</h3>
									</div>
								</div>
							) */}
							
							{/* jobData.status == 'pending' && (
								<p className="ml-3">Data Entry Completed: {formatDate(jobData.finalized_at, 'MMMM Do, YYYY h:mm A')}</p>
							)}
							{jobData.status == 'submitted' && (
								<p className="ml-3">Data Imported: {formatDate(jobData.imported_at, 'MMMM Do, YYYY h:mm A')}</p>
							) */}
						</div>
					</div>

					{importResult && (
						<div className="row">
							<div className="col col-xl-10">
								<Alert color="danger" isOpen={importResult} toggle={() => { setImportMessage(''); setImportResult(false); }}>{importMessage}</Alert>
							</div>
						</div>
					)}
					
					{isLoading ? (
						<div id="form-page" className={'col-12 col-xl-10'} data-spy="scroll" data-target="#quick-nav" data-offset="0" style={{ display: 'relative' }}>
							<div className="card">
								<h2 className="card-header">
									NCS Job App
								</h2>
								<div className="card-body">
									<i className="fas fa-sync-alt fa-spin mr-1" ></i> Loading job form and data
								</div>
							</div>
						</div>
					) : (
						<>
							<ShowForm id={jobData.id} updateTabs={(status) => { setJobData({ ...jobData, "status": status }) }} />
							{(isAuthenticated && !isLoading) && (
								<>
									{jobData.status !== 'archived' && (
										<Button color="warning" onClick={handleArchive}>
											Archive Job
										</Button>
									)}
									<Button color="danger" className="ml-3" onClick={handleDelete}>
										Delete Job
									</Button>
								</>
							)}
						</>
					)}
				</TabPane>

				{activeTab === tabs.SHARE && jobData.status != "submitted" && (
					<TabPane tabId={tabs.SHARE}>
						{jobData.status == "pending" && (
							<Alert color="warning">
								<i className="fas fa-info-circle mr-3"></i> 
								You can still create/send sharable links, but note that while the project is in a "Review" status, 
								the job will not be able to be edited by any non-logged in users.
							</Alert>
						)}
						<Row>
							<Col>
								<EmailLinkHistory 
									id={jobData.id} 
									jobName={jobData.name} 
									email_list={[ ...jobData.emails ]}
									addNew={newEmail => updateEmailList(newEmail)}
									updateRec={(newEmail) => updateEmail(newEmail)} />
							</Col>
						</Row>
						<Row>
							<Col>
								<ShareLink 
									form={jobData.form} 
									histories={[ ...jobData.histories ]} 
									addNew={newLink => updateLinkList(newLink)} />
							</Col>
						</Row>
					</TabPane>
				)}
				
				{activeTab === tabs.HISTORY && (
					<TabPane tabId={tabs.HISTORY}>
						<div className="row">
							<div className="col-md-12 col-lg-8">
								<div className="fieldBuilderPreview">
									<h3>
										Job History
										{!refreshingHistory && (
											<i className="fas fa-sync-alt ml-3" style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => refreshHistory()}></i>
										)}
									</h3>
									{refreshingHistory ? (
										<div>
											<i className="fas fa-sync-alt fa-spin ml-3"></i>
											<span className="ml-3">Refreshing History</span>
										</div>
									) : (
										<Table>
											<thead>
												<tr>
													<th>Date</th>
													<th>Time</th>
													<th>Action</th>
													<th>User</th>
													<th>Description</th>
												</tr>
											</thead>
											<tbody>
												{jobData.stats.map(stat => {
													let action_desc = setHistoryAction(stat.action);
													return (
														<tr key={stat.id}>
															<td>{formatDate(stat.entry_date, 'L')}</td>
															<td>{formatDate(stat.entry_date, 'LT')}</td>
															<td>{action_desc}</td>
															<td>{stat.user_id != 0 ? stat.user.name : 'Guest'}</td>
															<td>{stat.parameter}</td>
														</tr>
													)
												})}
											</tbody>
										</Table>
									)}
								</div>
							</div>
						</div>
					</TabPane>
				)}

				{activeTab === tabs.NOTES && (
					<TabPane tabId={tabs.NOTES}>
						<div className="row">
							<div className="col-md-12 col-lg-8">
								<div className="fieldBuilderPreview">
									<h3>
										<button type="button" className="float-right btn btn-sm btn-primary"
											onClick={() => setModal(true)}>Add Note</button>
										Job Notes 
									</h3>
									{jobData.notes.length == 0 ? (
										<div className="p-3 my-3">
											There are no notes for this job.
										</div>	
									) : (
										<>
											{jobData?.notes?.map(note => {
												return (
													<Card key={note.id}>
														<CardHeader>
															<button className="btn btn-sm btn-danger float-right" 
																onClick={() => deleteNote(note.id)}>Delete</button>
															{note.user.name} ({formatDate(note.created_at, 'L')})
														</CardHeader>
														<CardBody>
															<CardText>
																<span style={{ whiteSpace: 'pre-wrap' }}>{note.description}</span>
															</CardText>
														</CardBody>
													</Card>
												)
											})}	
										</>
									)}
								</div>
							</div>
						</div>
					</TabPane>
				)}
			</TabContent>
		</div>
	)
}
export default ShowJob;
