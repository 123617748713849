import React, { Fragment } from 'react'
import { classnames } from '../../utils/classnames'

import './styles/Input.css'
import RequiredBadge from './RequiredBadge'

export const Input = ({
	label,
	name,
	onChange,
	pattern,
	placeholder,
	type = 'text',
	value = '',
	className,
	description = '',
	autoComplete = 'off',
	autofocus = false,
	isRequired = false,
	readOnly = false,
	...rest
}) => {
	if ( !readOnly ) {
		return (
			<Fragment>
				{label && (
					<label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
				)}
				{description && (
					<div className="input--description">{description}</div>
				)}
				<input
					className={classnames('form-control', className)}
					autoComplete={autoComplete}
					autoFocus={autofocus}
					name={name}
					onChange={onChange}
					pattern={pattern}
					placeholder={placeholder}
					type={type}
					value={value}
					required={isRequired}
					{...rest}
				/>
				<div className="invalid-feedback" />
			</Fragment>
		)
	} else {
		return (
			<Fragment>
				<label>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
				<span>{value}</span>
			</Fragment>
		)
	}
}
