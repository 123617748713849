import React, { useState, useEffect, useContext } from 'react'

import { can } from '../../utils/can'

import { JobAppContext } from '../../GlobalState';
import {
	getUsers, postUser, postUpdateUser,
	getRoles, getWorkflows, deleteMembership, 
	uploadUserListFile
} from '../../api'
import { getContact } from '../../api/ncs'

import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap'

import { Close } from '../partials/Close'
import { Form, Input } from '../../components'
import { ListDropDown } from '../partials/ListDropDown'

import Roles from '../roles/Roles'
import Workflows from '../workflows/Workflows'

const UserItem = ({ user, /* company_contact, */ onEdit, onDelete, auth }) => {
	return (
		<tr>
			<td>{user.user.name /* + (company_contact ? ' (company contact)' : '') */}</td>
			<td>{user.user.email}</td>
			<td style={{ textTransform: 'capitalize' }}>
				{user.role.name ? user.role.name : ''}
			</td>
			<td>{user.user.cnt_id}</td>
			<td>
				{user.user.api_key && user.user.api_key != '' ? (
					<i className="fas fa-check" title={user.user.api_key} style={{ color: 'green' }}></i>
				) : (
					<i className="far fa-times-circle" style={{ color: 'red' }}></i>
				)}
			</td>
			<td>
				<i className="far fa-edit mr-2" onClick={onEdit} style={{ cursor: 'pointer' }}></i>
				{auth.id !== user.user_id && <Close color="black" onClick={onDelete} />}
			</td>
		</tr>
	)
}

const UsersPage = (props) => {

	const { accessToken, user, locations } = useContext(JobAppContext);

	const [ id, setId ] = useState(0);
	const [ name, setName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ cnt_id, setCntId ] = useState('');
	const [ api_key, setApiKey ] = useState('');
	const [ value, setValue ] = useState('');

	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ isFetchingUsers, setIsFetchingUsers ] = useState(true);
	const [ userList, setUserList ] = useState([]);
	const [ error, setErrors ] = useState('');

	const [ roles, setRoles ] = useState([]);
	const [ assignable_roles, setAssignableRoles ] = useState([]);
	const [ workflows, setWorkflows ] = useState([]);
	const [ addUserModal, setAddUserModal ] = useState(false);

	const [ searchingAPI, setSearchingAPI ] = useState(false);

	// upload user list state vars
	const [ uploadModal, setUploadModal ] = useState(false);
	const [ uploadText, setUploadText ] = useState('');
	const [ user_file_label, setUserFileLabel ] = useState('Choose File');

	useEffect(() => {
		
		// get list of users
		getUsers(accessToken).then(data => {
			setIsFetchingUsers(false);
			setUserList(data.users)
		})

		getRoles().then(data => {
			setRoles(data);
			setAssignableRoles(data.filter(role => role.assign_workflow == 1));
		});
		
		if ( can(['workflows'], user) ) {
			getWorkflows().then(data => {
				setWorkflows(data);
				setValue(data[0].role.id);
			})
		}
	}, [])

	const handleSubmit = () => {
		if ( id != 0 ) {
			// console.log('dispatching updateUser', this.state)
			postUpdateUser({
				id, name, email, cnt_id, api_key, value
			}, accessToken).then(resp => {
				setUserList([ ...resp ]);
			})
		} else {
			// console.log('dispatching addUser', this.state)
			postUser({
				id: 0, name, email, cnt_id, api_key, value
			}, accessToken).then(resp => {
				setUserList([
					...userList,
					resp
				]);
			})
		}
		setId(0);
		setName('');
		setEmail('');
		setCntId('');
		setApiKey('');
		setValue(roles[0].id);
		setAddUserModal(false);
	}

	const handleEdit = (user) => {
		setId(user.user_id);
		setName(user.user.name);
		setEmail(user.user.email);
		setCntId(user.user.cnt_id ?? '');
		setApiKey(user.user.api_key ?? '');
		setValue(user.role_id);
		setAddUserModal(true);
	}

	const lookupAPI = () => {
		if ( cnt_id != '' ) {

			// console.log('looking up API key for CNT ID', this.state.cnt_id)
			setSearchingAPI(true);
			
			getContact(cnt_id, user.api_key).then(resp => {
				
				setSearchingAPI(false);
				if ( resp.errors ) {
					alert('Error: ' + resp.errors[0].message);
				} else {
					setApiKey(resp.api_key ?? '');
				}
				
			})

		} else {
			alert('A CNT ID is needed to look up the user\'s API Key')
		}
	}

	const handleDelete = membership => {
		if ( confirm('Remove user membership for: ' + membership.user.name) ) {
			deleteMembership(membership.id).then(data => {
				setUserList(data)
			})
		}
	}

	const toggleModal = () => {
		addUserModal ? closeModal() : openModal()
	}

	// open the "Add User" modal
	const openModal = () => {
		setAddUserModal(true);
	}

	// clear the "Add User" form, and close the modal
	const closeModal = () => {
		setId(0);
		setName('');
		setEmail('');
		setCntId('');
		setApiKey('');
		// company_contact: false,
		// value: prevState.workflows[0].role.id,
		setAddUserModal(false);
	}

	const uploadUserFile = event => {
		const target = event.target;
		const { files } = target;

		setUserFileLabel(files[0].name);
		setUploadModal(true);
		setUploadText('Loading Users records found in "' +files[0].name+ '"');

		let formData = new FormData()
		formData.append('file', files[0]);
		formData.append('div_id', locations[0].DIV_ID)

		uploadUserListFile(formData).then(oResp => oResp.json()).then(data => {
			setUserList([
				...userList,
				...data.users
			])
			setUserFileLabel('Choose File');
			setUploadText(data.upload_count +' User records added');
		}).catch(err => {
			console.log('File upload failed!', err);
		});
	}
  
	const toggleUploadModal = () => {
		setUploadModal(false);
		setUploadText('');
	}

	return (
		<div className="">

			{roles.length > 0 && 
				<Roles roles={roles} />
			}

			{assignable_roles.length > 0 && workflows.length > 0 && 
				<Workflows roles={assignable_roles} workflows={workflows} />
			}

			<hr />

			{/* ***** Add User Form ***** */}
			<Modal isOpen={addUserModal} toggle={toggleModal}>
				<ModalHeader toggle={toggleModal}>
					{(id == 0 ? 'Add New' : 'Edit') + ' User'}
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit}>
						<div className="form-group">
							<div className="form-row">
								<div className="col">
									<Input
										label="Name"
										name="name"
										onChange={(e) => setName(e.target.value)}
										type="text"
										value={name}
									/>
								</div>
							</div>
							<div className="form-row my-3">
								<div className="col">
									<Input
										label="Email"
										name="email"
										onChange={(e) => setEmail(e.target.value)}
										type="email"
										value={email}
										pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
									/>
								</div>
							</div>
							
							<div className="form-row my-3">
								<div className="col">
									<Input
										label="CNT ID"
										name="cnt_id"
										onChange={(e) => setCntId(e.target.value)}
										type="text"
										value={cnt_id}
									/>
								</div>
							</div>
							<div className="form-row my-3">
								<div className="col">
									{searchingAPI ? (
										<span>
											<i className="fas fa-sync-alt fa-spin mr-1" ></i> 
											Searching for API Key...
										</span>
									) : (
										api_key != '' ? (
											<span>
												<Input
													label="API Key"
													name="api_key"
													onChange={(e) => setApiKey(e.target.value)}
													type="text"
													value={api_key}
													readOnly={true}
												/>
												<button type="button" 
														className={'btn btn-sm btn-danger'}
														onClick={() => setApiKey('')}>
													Clear API Key
												</button>
											</span>
										) : (
											<button type="button" 
													className={'btn btn-sm btn-primary' + (cnt_id == '' ? ' btn-disabled' : '')}  
													disabled={cnt_id == '' ? true : false}
													onClick={() => lookupAPI()}>
												Look up API
											</button>
										)
									)}
								</div>
							</div>
							
							<div className="form-row my-3">
								<div className="col">
									<ListDropDown
										name="value"
										label="Role"
										selected={value}
										onChange={(e) => setValue(e.target.value)}
										limit={0}
										items={roles.length > 0 ? [ ...roles ] : []}
									/>
								</div>
							</div>

						</div>
						{id === 0 && (
							<div className="my-3">
								<cite>&#42; Initial password will be your email address: { user.email }</cite>
							</div>
						)}
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button className="mr-2" color="primary" 
							disabled={isSubmitting || name.trim() === '' || email.trim() === ''}
							onClick={() => handleSubmit()}>
						{isSubmitting ? 
							'Submitting...' : 
							id !== 0 ? 'Save Changes' : 'Create New User'}
					</Button>
					<Button onClick={() => closeModal()}>Cancel</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={uploadModal} toggle={toggleUploadModal}>
				<ModalBody>
					<div>{uploadText}</div>
				</ModalBody>
			</Modal>

			<h4 style={{ backgroundColor: '#EAEAEA', padding: '20px' }}>
				Application Users
				<Button className="ml-4" color="primary" size="sm" onClick={() => openModal()} disabled={isSubmitting}>
					Add User
				</Button>
				<div className="float-right" style={{ fontSize: '1rem' }}>
					<div className="custom-file" style={{ marginRight: '100px' }}>
						<input type="file" name="user_list" value="" className="custom-file-input" onChange={uploadUserFile} />
						<label className="custom-file-label" htmlFor="user_list">{user_file_label}</label>
					</div>
				</div>
			</h4>
			<div className="m-3">
				<Table responsive style={{ maxWidth: '1200px' }}>
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Role</th>
							<th>CNT ID</th>
							<th>
								API <i className="far fa-question-circle ml-2" title="An API is needed to Import jobs"></i>
							</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						{error ? (
							<tr><td>Error! {error}</td></tr>
						) : isFetchingUsers ? (
							<tr><td>Loading...</td></tr>
						) : (
							userList?.map(userRow => (
								<UserItem key={userRow.id} user={userRow} auth={user} 
									// company_contact={auth.user.memberships[0].company.contact_id == user.user.id ? true : false}
									onEdit={() => handleEdit(userRow)}
									onDelete={() => handleDelete(userRow)} />
							))
						)}
					</tbody>
				</Table>
			</div>
		</div>
	)
}

export default UsersPage;