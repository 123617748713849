import React, { useContext } from 'react'
import { JobAppContext } from '../../GlobalState';

import { Input } from './Input'
import { Select } from './Select';
import RequiredBadge from './RequiredBadge';
import GoogleMapsAutocomplete from './GoogleMapsAutocomplete';

const Place = (props) => {

	const { states } = useContext(JobAppContext);

	const {
		fldType,
		label,
		onChange,
		updateAnswers,
		value,
		name,
		description,
		parent,
		isRequired = false,
		children,
		hasCounty = true,
		type_id,
		allow_none,
		readOnly = false,
		// states
		fields,			// TODO: This is for upcoming field-level style/config settings
		style,			// This handles form-level UI style settings
	} = props

	const printContactFields = () => {
		return (
			<>
				<div className="form-row">
					<div className="col">
						<Input
							label={type_id === null ? label + ' Name' : 'Company Name'}
							name={parent + '.address.company'}
							onChange={onChange}
							type="text"
							isRequired={isRequired}
							value={value?.address?.company}
							placeholder={!style?.compact ? type_id === null ? label + ' Name' : 'Company Name' : null}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="col-md-6">
						<Input
							label="Contact Name"
							name={parent + '.address.name'}
							onChange={onChange}
							type="text"
							isRequired={false}
							value={value?.address?.name}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Phone"
							name={parent + '.address.phone'}
							onChange={onChange}
							isRequired={false}
							placeholder={!style?.compact ? 'Phone' : null}
							type="tel"
							minLength="7"
							maxLength="50"
							value={value?.address?.phone}
						/>
					</div>
				</div>
			</>
		)
	}

	const printAddressForm = () => {
		return (
			<>
				<div className="form-row">
					<div className="col">
						<GoogleMapsAutocomplete
							label={label}
							name={parent + ".address.address"}
							onChange={onChange}
							onSelect={(address) => {
								updateAnswers({
									[parent]: { address },
								});
							}}
							value={value?.address?.address}
							isRequired={isRequired}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="col">
						<Input
							label="Street Address 2"
							name={parent + '.address.address_2'}
							onChange={onChange}
							type="text"
							isRequired={false}
							placeholder=""
							value={value?.address?.address_2}
						/>
					</div>
				</div>
			
				{(fldType === "address_plus" || style?.addr_plus) && (
					<>
						<div className="form-row">
							<div className="col">
								<Input
									label="Parcel Number"
									name={parent + '.address.parcel_no'}
									onChange={onChange}
									type="text"
									isRequired={false}
									value={value?.address?.parcel_no}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6">
								<Input
									label="Lot Number"
									name={parent + '.address.lot_no'}
									onChange={onChange}
									type="text"
									isRequired={false}
									value={value?.address?.lot_no}
								/>
							</div>
							<div className="col-md-6">
								<Input
									label="Block Number"
									name={parent + '.address.block_no'}
									onChange={onChange}
									isRequired={false}
									placeholder=""
									value={value?.address?.block_no}
								/>
							</div>
						</div>
					</>
				)}

				<div className="form-row">
					<div className="col-md-4">
						<Input
							label="City"
							name={parent + '.address.city'}
							onChange={onChange}
							type="text"
							isRequired={isRequired}
							value={value?.address?.city}
						/>
					</div>
					<div className="col-md-2">
						<Select
							label="State"
							onChange={onChange}
							name={parent + '.address.state'}
							isRequired={false}
							value={value?.address?.state}                                      
							children={states.map((state, index) => (
								<option key={index} value={state.state_code}>{state.state_desc}</option>
							))}
						/>
					</div>
					<div className="col-md-3">
						<Input
							label="Zip"
							name={parent + '.address.zip'}
							isRequired={isRequired}
							onChange={onChange}
							type="text"
							value={value?.address?.zip}
						/>
					</div>
					{hasCounty === true && (
						<div className="col-md-3">
							<Input
								label="County"
								name={parent + '.address.county'}
								isRequired={false}
								onChange={onChange}
								type="text"
								value={value?.address?.county}
							/>
						</div>
					)}
				</div>
				
			</>
		)
	}

	const printEmailField = () => {
		return (
			<div className="form-row">
				<div className="col">
					<Input
						label="Email Address"
						name={parent + '.address.email'}
						isRequired={false}
						onChange={onChange}
						type="email"
						value={value?.address?.email}
						pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
					/>
				</div>
			</div>
		)
	}

	if ( !readOnly ) {

		return (
			<div className={'form-group address_section_' +(type_id ? type_id : '0')+ (style?.compact ? ' compact' : '')}>
				<fieldset className={style?.fieldset ? 'show' : 'hide'}>
				<legend className={style?.fieldset ? 'show' : 'hide'}>{label}</legend>
					
					{allow_none && type_id && type_id != 2 && (
						<>
							<label>
								<input type="checkbox" name={parent+'.None.'+type_id} onChange={onChange} value="Y" /> None
							</label>
							<label>
								<input type="checkbox" name={parent+'.Unknown.'+type_id} onChange={onChange} value="Y" /> Unknown
							</label>
						</>
					)}

					{description && (
						<div className="form-row">
							<div className="col">
								<div className="input--description">
									{description}
								</div>
							</div>
						</div>
					)}

					{type_id && (type_id == 10 || type_id == 11 || type_id == 12) && (
						<div className="form-row">
							<div className="col">
								<Input
									label={'Bond Number'}
									name={parent + '.address.bond_no'}
									onChange={onChange}
									type="text"
									isRequired={isRequired}
									value={value?.address?.bond_no}
								/>
							</div>
						</div>
					)}

					<div className="row">
						{style?.layout == 'columns' ? (
							<>
								<div className="col">
									{printContactFields()}
									{printEmailField()}
								</div>
								<div className="col">
									{printAddressForm()}
								</div>
							</>
						) : (
							<div className="col">
								{printContactFields()}
								{printAddressForm()}
								{printEmailField()}
							</div>
						)}
					</div>
					{children}
				</fieldset>
			</div>
		)

	} else {

		var addr = value['address']
		if ( 
			addr.company == '' && 
			addr.name == '' && 
			addr.phone == '' && 
			addr.address == '' && 
			addr.address_2 == '' && 
			addr.city == '' && 
			addr.state == '' && 
			addr.zip == '' && 
			addr.email == ''
		) {
			return <div>No Address Data Found</div>
		} else {
		
			// build address display
			let address = [];
			if ( addr.company != '' ) address.push(addr.company);
			if ( addr.name != '' ) address.push(addr.name);
			if ( addr.phone != '' ) address.push(addr.phone);
			if ( addr.address != '' ) address.push(addr.address);
			if ( addr.address_2 != '' ) address.push(addr.address_2);
			if (addr.city != '' && addr.state != '' && addr.zip != '') {
				address.push(addr.city+ ", " +addr.state+ " " +addr.zip);
			} else {
				if ( addr.city != '' ) address.push(addr.city);
				if ( addr.state != '' ) address.push(addr.state);
				if ( addr.zip != '' ) { address.push(addr.zip); }
			}
			if ( addr.county != '' ) { address.push(addr.county); }
			if ( addr.email != '' ) { address.push(addr.email); }
		
			return (
				<div className="form-group">
					
					<fieldset className={style?.fieldset ? 'show' : 'hide'}>
						<legend className={style?.fieldset ? 'show' : 'hide'}>{label}</legend>
						{addr?.bond_no && (
							<div className="form-row">
								<div className="col">
									<label>Bond Number{' '}</label>
									<span>{addr?.bond_no}</span>
								</div>
							</div>
						)}
						<div className="form-row">
							<div className="col">
								<label style={{ verticalAlign: 'top' }}>Address {isRequired === 1 && <RequiredBadge value={address?.length > 0} />}{' '}</label>
								<span style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }}>
									{address.map(addr_field => addr_field + '\n' )}
								</span>
							</div>
						</div>
					</fieldset>
				</div>
			)

		}
	} // end readOnly check
}
export default Place;