const METHOD = {
	GET: 'GET',
	HEAD: 'HEAD',
	PUT: 'PUT',
	DELETE: 'DELETE',
	PATCH: 'PATCH',
	POST: 'POST',
}

export const api = {
	root: window.ncs_api,

	call: async (url, parameters) => {
		const finalUrl = url.indexOf('http') === 0 ? url : `${api.root}${url}`
		const response = await fetch(finalUrl, parameters)

		return response
	},

	parameters: (apiKey, method = METHOD.GET, body = {}, mode = 'cors') => {
		const withBody = [METHOD.PUT, METHOD.PATCH, METHOD.POST]
		const params = {
			method,
			headers: {
				'X-Api-Key': apiKey,
				'Content-Type': 'application/json',
			},
			// credentials: "include",
			json: true,
		}

		if (withBody.indexOf(method) !== -1) {
			params.body = JSON.stringify(body)
			if (method === METHOD.PUT) {
				params.headers['Content-Length'] = 0
			}
		}

		return params
	},

	get: async (url, api_key) => {
		const response = await api.call(url, api.parameters(api_key))
		return response.json()
	},

	getJson: async (url, api_key) => {
		const response = await api.call(url, api.parameters(api_key))
		return response.json()
	},

	post: async (url, body = {}, api_key) => {
		const response = await api.call(
			url,
			api.parameters(api_key, METHOD.POST, body),
		)
		return response.json()
	},

	delete: async (url, api_key) => {
		const response = await api.call(url, api.parameters(api_key, METHOD.DELETE))
		return response.json()
	},

	putFile: async (url, body = {}, formData = false, api_key) => {
		const response = await api.call(
			url,
			api.parameters(api_key, METHOD.PUT, body, 'cors', formData),
		)
		return response
	},

	patch: async (url, body = {}, api_key) => {
		const response = await api.call(
			url,
			api.parameters(api_key, METHOD.PATCH, body),
		)
		return response.json()
	},
}

/* ***** Contact API calls ***** */

export const getContacts = (div_id, api_key) => {
	return api.get(`/ncs/client/2/contacts/json?DIV_ID=${div_id}`, api_key)
}

export const getContact = (cnt_id, api_key) => {
	return api.get(`/cli/jobinformation/1/contact/${cnt_id}/json`, api_key)
}

/* ***** Division API calls ***** */

export const getLocations = (api_key) => {  
  return api.get(`/ncs/client/2/divisions/json`, api_key)
}

export const getLocationsByCNTID = (cnt_id, api_key) => {
  return api.get(`/ncs/client/2/divisions/json?CNT_ID=${cnt_id}`, api_key)
}

/*
export const getLocation = (div_id, api_key) => {
  return api.get(`/ncs/client/2/divisions/json?DIV_ID=${div_id}`, api_key)
}
*/