const METHOD = {
	GET: 'GET',
	HEAD: 'HEAD',
	PUT: 'PUT',
	DELETE: 'DELETE',
	PATCH: 'PATCH',
	POST: 'POST',
}

export const api = {
	root: window.app_api,

	call: async (url, parameters) => {
		const finalUrl = url.indexOf('http') === 0 ? url : `${api.root}${url}`;
		const response = await fetch(finalUrl, parameters).then((resp) => {
			if ( resp.status === 401 ) {
				// clear local session variables
				localStorage.removeItem('accessToken')
				localStorage.removeItem('user')

				// send user back to login page
				window.location = '/login';
				// throw new Error(resp.status);
			}
			return resp;
		})
		return response;

	},

	parameters: (
		accessToken,
		method = METHOD.GET,
		body = {},
		mode = 'cors',
		api_key = '',
	) => {
		const withBody = [METHOD.PUT, METHOD.PATCH, METHOD.POST]
		const params = {
			method,
			headers: {
				...(accessToken && { Authorization: `bearer ${accessToken}` }),
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				'X-Api-Key': api_key,
			},
			credentials: "include"
		}

		if (withBody.indexOf(method) !== -1) {
			params.body = JSON.stringify(body)
			if (method === METHOD.PUT) {
				params.headers['Content-Length'] = 0
			}
		}

		return params
	},

  /* ************************************************************************ */
  /* ***** API-related functions                                        ***** */
  /* ************************************************************************ */

	get: async (url, accessToken) => {
		const response = await api.call(url, api.parameters(accessToken))
		return response.json()
	},

	getJson: async (url, accessToken) => {
		const response = await api.call(url, api.parameters(accessToken))
		return response.json()
	},

	post: async (url, accessToken, body = {}) => {
		const response = await api.call(
			url,
			api.parameters(accessToken, METHOD.POST, body),
		)
		return response.json()
	},

	delete: async (url, accessToken) => {
		const response = await api.call(
			url,
			api.parameters(accessToken, METHOD.DELETE),
		)
		return response.json()
	},

	putFile: async (url, accessToken, body = {}, formData = false) => {
		const response = await api.call(
			url,
			api.parameters(accessToken, METHOD.PUT, body, 'cors', formData),
		)
		return response
	},

	patch: async (url, accessToken, body = {}) => {
		const response = await api.call(
			url,
			api.parameters(accessToken, METHOD.PATCH, body),
		)
		return response.json()
	},

	auth: async (url, body = {}) => {
		const response = await api.call(url, {
			method: METHOD.POST,
			headers: {
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json',
				accept: 'application/json',
			},
			body: JSON.stringify(body),
			json: true,
		})

		return response
  },
}

/* ***** DEPRECATED - DAP - 2.05.19 ***** */
/*
export const testLogin = data => api.auth('/auth/login', data)

export const generateUrl = async data => {
	const URL_SHORTNER = 'https://api.ncscredit.com/short/1/shorten/json'
	const response = await fetch(URL_SHORTNER, {
		method: METHOD.POST,
		headers: {
			'Cache-Control': 'no-cache',
			'Content-Type': 'application/json',
			'x-api-key': 'gPHHNDp8aCvlZgXbYrdMFjNsNdsTyEWPxJ3ILqg3',
		},
		body: JSON.stringify(data),
		json: true,
	})
	return response.json()
}
*/

/* ************************************************************************** */
/* ***** User-related functions                                         ***** */
/* ************************************************************************** */

// auth
// doesnt require JWT.. but returns one!
const authParameters = data => ({
	method: METHOD.POST,
	headers: {
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'access-control-request-method': 'POST',
	},
	body: JSON.stringify(data),
	json: true,
})

// post register
export const authRegister = async data => {
	const AUTH_REGISTER_ENDPOINT = `${api.root}/auth/register`

	const response = await fetch(AUTH_REGISTER_ENDPOINT, authParameters(data))
	return response
}

// post login
export const authLogin = async data => {
	const AUTH_LOGIN_ENDPOINT = `${api.root}/auth/login`
	const response = await fetch(AUTH_LOGIN_ENDPOINT, authParameters(data))
	return response
}

// request reset link
export const requestResetLink = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/resetRequest`, accessToken, data)
	return response
}
// post reset
export const authReset = async data => {
	const AUTH_RESET_ENDPOINT = `${api.root}/auth/reset`
	const response = await fetch(AUTH_RESET_ENDPOINT, authParameters(data))
	return response
}

// routes for the UsersPage component
export const getUsers = accessToken => api.getJson('/users', accessToken)

export const postUser = (data, accessToken) =>
	api.post('/user', accessToken, data)

export const postUpdateUser = (data, accessToken) =>
	api.patch('/user/' + data.id, accessToken, data)

export const uploadUserListFile = async data => {
	const response = await fetch(`${api.root}/userFile`, {
		headers: {
			Authorization: `bearer ${localStorage.getItem('accessToken')}`,
		},
		method: 'POST',
		body: data,
	})
	return response
}

  /*
export const user_config = async () => {
	const accessToken = localStorage.getItem('accessToken');
	const response = await api.get(`/user/config`, accessToken);
	return response;
}
*/

export const saveUserConfig = async data => {
  const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/settings/userConfig`, accessToken, data)
	return response
}

export const changePassword = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/settings/password`, accessToken, data)
	return response
}

export const getPermissions = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/permissions', accessToken)
}

export const deleteMembership = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/membership/${id}`, accessToken)
}
/*
export const getKeys = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/settings/keys', accessToken)
}
*/
export const getMappedFields = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/form/types', accessToken)
}

/* ************************************************************************** */
/* ***** Job Page(s) functions                                          ***** */
/* ************************************************************************** */

// called from JobsPage.js ( when fetchJobs is dispatched in componentWillMount )
/*
export const getJobs = accessToken => api.getJson('/jobs', accessToken)
*/

// called from JobsPage.js ( when fetchJobs is dispatched in componentWillMount )
export const getJobList = ( status ) => {
	const accessToken = localStorage.getItem('accessToken')
	const response = api.getJson(`/jobs/${status}`, accessToken);
	return response
}

// called from ShowJob.js
export const getJob = async id => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get(`/job/${id}`, accessToken)
	return response
}

// called from ShowForm.js
export const getJobForm = async (data, accessToken) => {
	let url = '';
	if ( data.id ) {
		url = `/build/${data.id}/filter/${data.limit ?? '0'}`
	} else if (data.uuid) {
		url = `/link/${data.uuid}`;
	} else {
		url = `/new/${data.appid}`;
		if ( data?.cntid ) {
			url = `/new/${data.appid}/${data.cntid}`;
		}
	}
	const response = await api.get(
		url,
		accessToken,
	)
	return response
}

export const getJobAnswers = async (data, accessToken) => {
	return await api.get(`/job/${data.id}/answers`, accessToken)
}

export const refreshJobHistory = async id => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get(`/job/${id}/stats`, accessToken)
	return response
}

export const searchJobs = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/search', accessToken, data)
	return response
}

export const updateStatus = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${data.id}/status`, accessToken, data)
	return response
}

export const finalizeJob = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${data.id}/finalize`, accessToken, data)
	return response
}

export const importJob = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${data.id}/import`, accessToken, data)
	return response
}

/*
export const patchFormData = (data, accessToken) => {
	const { id } = data
	return api.patch(`/job/${id}`, accessToken, data)
}
*/

export const newJob = async (data, accessToken) => {
	const response = await api.post('/new', accessToken, data)
	return response
}

export const addJob = async (data) => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/job', accessToken, data)
	return response
}

/*
export const addJob = async (data, accessToken) => {
	const response = await api.post('/job', accessToken, data)
	return response
}
*/

export const updateJob = (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.patch(`/job/${id}`, accessToken, data)
}

export const email_external = (data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/email`, accessToken, data)
}

export const resendEmail = (data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/resend`, accessToken, data)
}

export const optOutEmail = (data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/optOut`, accessToken, data)
}

/* ************************************************************************** */

export const addService = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/service', accessToken, data)
	return response
}

export const listServices = async () => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get('/services', accessToken)
	return response
}

export const findService = (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get(`/service/${id}`, accessToken)
}

export const updateService = data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = api.patch(`/service/${data.id}`, accessToken, data)
	return response
}

export const removeService = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/service/${id}`, accessToken)
}

/* ************************************************************************** */

export const addCustomer = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/customer', accessToken, data)
	return response
}

export const listCustomers = async () => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get('/customers', accessToken)
	return response
}

export const findCustomer = (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get(`/customer/${id}`, accessToken)
}

export const updateCustomer = data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = api.patch(`/customer/${data.id}`, accessToken, data)
	return response
}

export const deleteCustomer = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/customer/${id}`, accessToken)
}

export const uploadCustomerFile = async data => {
	const response = await fetch(`${api.root}/customerFile`, {
		headers: {
			Authorization: `bearer ${localStorage.getItem('accessToken')}`,
		},
		method: 'POST',
		body: data,
	})
	return response
}

/* ************************************************************************** */

export const createHistoryEntry = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/job/history', accessToken, data)
	return response
}

export const updateHistory = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/history/${data.id}`, accessToken, data)
	return response
}

export const createJobNote = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/note', accessToken, data)
	return response
}
export const deleteJobNote = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/note/${id}`, accessToken)
}

/* ************************************************************************** */
/* Attachment actions (files that are linked to a specific job)               */
/* ************************************************************************** */
export const uploadAttachment = async data => {
	const response = await fetch(`${api.root}/attach`, {
		headers: {
			Authorization: `bearer ${localStorage.getItem('accessToken')}`,
		},
		method: 'POST',
		body: data,
	})
	return response
}

/*
export const getAttachments = job_id => {
	const accessToken = localStorage.getItem('accessToken')
	const response = api.get(`/job/${job_id}/attachments`, accessToken)
	return response
}
*/

export const deleteAttachment = async attachment => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.delete(`/attachment/${attachment}`, accessToken)
	return response
}

/* ************************************************************************** */
/* Test Routes ************************************************************** */
/* ************************************************************************** */
export const testDocuware = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${data.id}/docuwareTest`, accessToken, data)
	return response
}

export const testRequest = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/testRequest/${data.id}`, accessToken, data)
	return response
}

export const checkProgress = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get(`/checkProgress/${id}`, accessToken)
}

export const checkLinkProgress = uuid => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get(`/checkLinkProgress/${uuid}`, accessToken)
}
/* ************************************************************************** */

export const canSubmitJobs = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/settings/submission', accessToken)
}

/*
export const submitJob = (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/job/${id}/submit`, accessToken, data)
}
*/

// TODO: 11.16 - to be deprecated (from JobSettings component)
export const jobSettingValues = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get(`/job/${id}/settings`, accessToken)
}
// 11.16 - Note: Will still use this for updating settings-level information.
export const saveUserData = async (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${id}/settings`, accessToken, data)
	return response
}
export const clearUserData = async (id, data) => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${id}/clearsetting`, accessToken, data)
	return response
}

/* ************************************************************************** */
/* ***** Form-related functions                                         ***** */
/* ************************************************************************** */

export const getAllForms = async () => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get('/forms', accessToken)
	return response
}

export const getFormSchema = async (data, accessToken) => {
	const response = await api.get(`/form/${data.id}`, accessToken)
	return response
}

export const deleteForm = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/form/${id}`, accessToken)
}

export const saveBuilderData = async (data, accessToken) => {
	const response = await api.post('/form', accessToken, data)
	return response
}

/*
export const updateBuilderData = async (data, accessToken) => {
	const response = await api.patch(`/form/${data.id}`, accessToken, data)
	return response
}
*/

export const uploadLogo = async data => {
	const response = await fetch(`${api.root}/form/logo`, {
		headers: {
			Authorization: `bearer ${localStorage.getItem('accessToken')}`,
		},
		method: 'POST',
		body: data,
	})
	return response
}

export const updateFormData = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.patch(`/form/${data.form_id}`, accessToken, data)
	return response
}

/* TODO - DAP - 8.4.20
 * This is to be moved to JobAppContext, and pulled from there when needed
 * useContext hook can only be used in a functional component though
*/
export const getStates = async () => {
	const accessToken = localStorage.getItem('accessToken')
	return await api.get(`/form/states`, accessToken)
}

// saving form data from the shared link page -> no authentication needed
export const saveLinkForm = async data => {
	const response = await api.post(`/link/${data.id}/results`, '', data)
	return response
}

// saving form data from normal link from within the app -> authentication needed
export const saveJobForm = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post(`/job/${data.id}/results`, accessToken, data)
	return response
}

/*
export const uploadFile = async data => {
	const response = await fetch(`${api.root}/settings`, {
		headers: {
			Authorization: `bearer ${localStorage.getItem('accessToken')}`,
		},
		method: 'POST',
		body: data,
	})
	return response
}
*/

export const saveFieldOrder = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/form/reorderFields', accessToken, data)
	return response
}

export const saveSectionOrder = async data => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/form/reorderSections', accessToken, data)
	return response
}

export const getFiles = async () => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get('/settings/files', accessToken)
	return response
}

/*
export const deleteFile = async file => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.delete(`/settings/files/${file}`, accessToken)
	return response
}
*/


export const loadReport = async (data) => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.post('/report/stats', accessToken, data)
	return response;
}

/* ************************************************************************** */
/* ***** Company-related functions                                      ***** */
/* ************************************************************************** */

export const getCompanies = async () => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get('/companies', accessToken)
	return response
}

export const getCompany = async id => {
	const accessToken = localStorage.getItem('accessToken')
	const response = await api.get(`/company/${id}`, accessToken)
	return response
}

export const createCompany = data => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/company`, accessToken, data)
}

export const updateCompany = (company, data) => {
	const accessToken = localStorage.getItem('accessToken');
	return api.patch(`/company/${company}`, accessToken, data);
}

/* ************************************************************************** */
/* ***** User Role related functions                                     ***** */
/* ************************************************************************** */

export const getRoles = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/roles', accessToken)
}

export const createRole = data => {
	const accessToken = localStorage.getItem('accessToken');
	return api.post(`/roles`, accessToken, data);
}

export const updateRole = (role, data) => {
	const accessToken = localStorage.getItem('accessToken');
	return api.post(`/roles/${role}`, accessToken, data);
}

export const deleteRole = id => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/role/${id}`, accessToken)
}

/* ************************************************************************** */
/* ***** Workflow-related functions                                     ***** */
/* ************************************************************************** */

export const createWorkflow = data => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/workflows`, accessToken, data)
}

export const updateWorkflow = (workflow, data) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.post(`/workflows/${workflow}`, accessToken, data)
}

export const getWorkflows = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/workflows', accessToken)
}

/*
export const getWorkflow = () => {
	const accessToken = localStorage.getItem('accessToken')
	return api.get('/workflow', accessToken)
}
*/

export const deleteWorkflow = (id, destination) => {
	const accessToken = localStorage.getItem('accessToken')
	return api.delete(`/workflow/${id}/${destination}`, accessToken)
}

export const updateOrder = workflows => {
	const accessToken = localStorage.getItem('accessToken')
	return api.patch('/workflows', accessToken, workflows)
}

// FormBuilder saveOrder