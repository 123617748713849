import { useQuery } from "@tanstack/react-query";
import { convertGoogleResponseToAddress, formatParcelResponse } from "./utils";

const googleMapsApiKey = "AIzaSyBaNQdTgz9e3YVJS3Pt34yM1ap8heNrQiY";

export class ParcelMapsApi {
  static getCoordinatesByAddress(address) {
    const formattedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${googleMapsApiKey}`;

    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to get coordinates");
      }
      return response.json();
    });
  }

  static getAddressByCoordinates(lat, lng) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsApiKey}`;
    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to get address");
      }
      return response.json();
    });
  }

  static async getParcelByCoordinates(lat, lng) {
    const params = new URLSearchParams({
      client: "5GZ1vfMRoT",
      v: "5",
      spatial_intersect: `POINT(${lng} ${lat})`,
      si_srid: "4326",
      return_buildings: "t",
    });

    return fetch(
      `https://reportallusa.com/api/parcels.php?${params.toString()}`
    ).then((response) => {
      if (!response.ok) {
        throw new Error("Failed to get parcel data");
      }
      return response.json().then((data) => data?.results);
    });
  }

  static async getParcelByAddress(address) {
    // First get coordinates
    const geocodeResponse = await this.getCoordinatesByAddress(address);
    const googleAddress = convertGoogleResponseToAddress(
      geocodeResponse?.results?.find(
        (result) => result.geometry.location_type === "ROOFTOP"
      )
    );

    if (
      !geocodeResponse.results ||
      geocodeResponse.results.length === 0 ||
      geocodeResponse?.results?.[0]?.geometry?.location_type != "ROOFTOP"
    ) {
      throw new Error("Address not found");
    }

    // Extract coordinates from the first result
    const location = geocodeResponse.results[0].geometry.location;
    const { lat, lng } = location;

    // Then get parcel data using coordinates
    const parcels = await this.getParcelByCoordinates(lat, lng);
    return parcels.map((parcel) => formatParcelResponse(parcel, googleAddress));
  }
}

export function useParcelByAddress({ address, city, state, zip }) {
  const fullAddress = [address, city, state, zip]?.filter(Boolean)?.join(" ");

  return useQuery(
    ["parcel", fullAddress],
    () => ParcelMapsApi.getParcelByAddress(fullAddress),
    {
      enabled: !!fullAddress,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}
