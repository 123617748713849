import React, { Fragment } from 'react'

import '../../assets/css/styles.css'
import RequiredBadge from './RequiredBadge'

export const Select = ({
  onChange,
  value,
  children,
  label,
  description,
  name,
  isRequired=false,
  readOnly=false
}) => {
  if ( !readOnly ) {
    return (
      <Fragment>
        {label && (
          <label htmlFor={name}>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
        )}
        {description && (
          <div className="input--description">{description}</div>
        )}
        <select value={value} onChange={onChange} name={name} className="form-control">
          { children }
        </select>
        <div className="invalid-feedback" />
      </Fragment>
    )
  } else {
    return (
      <div>
        <label>{label} {isRequired === 1 && <RequiredBadge value={value} />}{' '}</label>
        <span>{value}</span>
      </div>
    )
  } 
}
