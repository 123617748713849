import { useScript } from "../hooks";
import { useState, useEffect, useContext } from "react";
import { formatParcelResponse, convertGoogleResponseToAddress } from "./utils";
import { ParcelMapsApi } from "./api";

var infoWindow = null;
var mapFeatures = [];

export default function ParcelMapScript({
  selectedCoords,
  map,
  onParcelSelect,
}) {
  const parcelScriptLoaded = useScript(
    "https://reportallusa.com/overlay/js.php?v=5&map=Google&client=5GZ1vfMRoT"
  );

  async function identifyParcel(latLng) {
    REP.Layer.Google.IdentifyByPoint(
      map,
      latLng,
      async function (resp) {
        const googleResp = await ParcelMapsApi.getAddressByCoordinates(
          latLng.lat(),
          latLng.lng()
        );

        const googleAddress = convertGoogleResponseToAddress(
          googleResp?.results?.find(
            (result) => result.geometry.location_type === "ROOFTOP"
          )
        );

        if (!resp.results.length) {
          onParcelSelect(null);
          return;
        }
        const parcel = resp.results[0];

        if (onParcelSelect) {
          onParcelSelect(formatParcelResponse(parcel, googleAddress));
        }
        renderParcelBounds(parcel);
      },
      function (errObj) {
        alert("REP Overlays error: " + errObj.message);
      }
    );
  }

  function renderParcelBounds(parcel) {
    for (var respKey in parcel) {
      var respVal = parcel[respKey];
      if (respVal === null) continue;

      if (respKey === "geom") {
        for (var i = 0; i < respVal.length; i++) {
          respVal[i].setOptions({
            fillColor: "#007bff4d",
            strokeColor: "#007bff",
          });
          respVal[i].setMap(map);
          mapFeatures.push(respVal[i]);

          // Get the bounds of the polygon
          const bounds = new google.maps.LatLngBounds();
          const path = respVal[i].getPath();
          path.forEach((point) => {
            bounds.extend(point);
          });

          // Get the center of the bounds
          const center = bounds.getCenter();

          // Add checkmark marker at the center point
          const checkmark = new google.maps.Marker({
            position: center,
            map: map,
            icon: {
              // Draw a circle with a checkmark inside
              path: "M -8,0 A 8,8 0 1,0 8,0 A 8,8 0 1,0 -8,0 M -4,0 L -1,3 L 4,-3", // Circle path + checkmark path
              fillColor: "#007bff",
              fillOpacity: 1,
              strokeColor: "#ffffff",
              strokeWeight: 2,
              scale: 1.5,
              anchor: new google.maps.Point(0, 0),
            },
          });
          mapFeatures.push(checkmark);
        }
      } else if (respKey === "buildings_poly") {
        // Iterate through each building record.
        for (var bldgRecIdx = 0; bldgRecIdx < respVal.length; bldgRecIdx++) {
          var bldgRec = respVal[bldgRecIdx];
          if (
            typeof bldgRec["geom"] === "undefined" ||
            bldgRec["geom"] === null
          )
            continue;
          var bldgRecGeoms = bldgRec["geom"];
          // Add each building geometry to map.
          for (var i = 0; i < bldgRecGeoms.length; i++) {
            bldgRecGeoms[i].setOptions({
              strokeColor: "#6c757d",
              strokeWeight: 1,
              fillOpacity: 0.0,
              clickable: false,
            });
            bldgRecGeoms[i].setMap(map);
            mapFeatures.push(bldgRecGeoms[i]);
          }
        }
      }
    }
  }

  // Initialize the REP Overlays
  useEffect(() => {
    if (parcelScriptLoaded)
      REP.Layer.Google.Initialize(map, { Return_Buildings: true });
  }, [parcelScriptLoaded]);

  // Onclick event listener
  useEffect(() => {
    google.maps.event.addListener(map, "click", function (event) {
      if (map.getZoom() < REP.Layer.Google.MIN_ZOOM) return;

      for (var i = 0; i < mapFeatures.length; i++) mapFeatures[i].setMap(null);
      mapFeatures = [];

      var latLng = event.latLng;
      identifyParcel(latLng);
    });
  }, [map]);

  // Identify the parcel when the selectedCoords change
  useEffect(() => {
    if (parcelScriptLoaded && selectedCoords) {
      identifyParcel({
        lat: () => selectedCoords.lat,
        lng: () => selectedCoords.lng,
      });
    }
  }, [parcelScriptLoaded, selectedCoords]);

  return null;
}
