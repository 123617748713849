import React, { useState, useEffect, useContext, useMemo } from "react";
import GoogleMapsAutocomplete from "./GoogleMapsAutocomplete";
import { Input } from "./Input";
import { Select } from "./Select";
import GoogleMapsCluster from "../parcelmaps/GoogleMapsCluster";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Collapse } from "react-bootstrap";
import { ParcelSearchIcon } from "./ProjectLocation";
import { validateAddress, validateOwner } from "../parcelmaps/utils";

export default function ParcelSearchModal({
  isOpen,
  toggle,
  searchValue,
  currentAddress,
  currentOwner,
  onChange,
}) {
  const [search, setSearch] = useState(searchValue);
  const [selectedGoogleResult, setSelectedGoogleResult] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [useAddress, setUseAddress] = useState(false);
  const [useOwner, setUseOwner] = useState(false);

  const addressValidation = validateAddress(
    currentAddress?.address,
    selectedParcel?.address
  );
  const isAddressMatch = Object.values(addressValidation).every((v) => v);

  const ownerValidation = validateOwner(
    currentOwner?.address,
    selectedParcel?.owner
  );
  const isOwnerMatch = Object.values(ownerValidation).every((v) => v);

  useEffect(() => {
    if (searchValue !== selectedGoogleResult) {
      setSearch(searchValue);
      setSelectedGoogleResult(searchValue);
    }
  }, [searchValue]);

  const marks = useMemo(
    () =>
      selectedGoogleResult
        ? [
            {
              address: selectedGoogleResult,
            },
          ]
        : null,
    [selectedGoogleResult]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="offcanvas offcanvas-right"
    >
      <div className="modal-header bg-light p-0 flex-column">
        <div className="w-100 p-3 d-flex align-items-center">
          <ParcelSearchIcon className="mr-3" />
          <div className="flex-grow-1">
            <GoogleMapsAutocomplete
              className="rounded-pill bg-white shadow-sm"
              placeholder="Search for property location..."
              onChange={(e) => setSearch(e.target.value)}
              onSelect={(address) => {
                const fullAddress = `${address.address}, ${address.city}, ${address.state} ${address.zip}`;
                setSearch(fullAddress);
                setSelectedGoogleResult(fullAddress);
              }}
              value={search}
            />
          </div>
        </div>
        {marks && (
          <div
            className="bg-white py-2 px-3 w-100 border-top"
            style={{ fontSize: 14, fontWeight: 500 }}
          >
            <i className="fa fa-mouse-pointer text-primary fa-xs mr-2" />
            Select a parcel on the map to view its information
          </div>
        )}
      </div>
      <ModalBody className="p-0 overflow-auto">
        {marks ? (
          <GoogleMapsCluster
            defaultZoom={18}
            onParcelSelect={(parcel) => {
              setSelectedParcel(parcel);
              setUseAddress(true);
              setUseOwner(true);
            }}
            showLocationMarkers={!selectedParcel}
            markers={marks}
          />
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100 gap-3">
            <h4 className="text-muted">
              <ParcelSearchIcon
                className="mb-3"
                style={{
                  transform: "scale(2)",
                }}
              />
            </h4>
            <h4 className="text-muted">
              Search for a property to view its information
            </h4>
          </div>
        )}
      </ModalBody>
      <div className="flex-column p-0">
        <Collapse in={selectedParcel ? true : false}>
          <div
            className={`w-100 position-relative ${
              selectedParcel ? "show" : ""
            }`}
          >
            <div
              className="position-absolute bg-white p-2 ml-2 shadow-sm"
              style={{ top: -50 }}
            >
              <span className="fst-italic text-muted">Parcel #</span>{" "}
              <span className="fw-bold">{selectedParcel?.parcel_id}</span>
            </div>
            <div
              className="bg-white border-bottom border-top py-2 px-3 w-100"
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              <i className="fa fa-check fa-xs text-primary mr-2" />
              Choose which information to use
            </div>
            <div className="d-flex bg-light">
              <div className={"w-50 p-2 text-muted d-flex flex-column"}>
                <button
                  className={`btn bg-white rounded-xl border-2 text-left ${
                    isAddressMatch
                      ? ""
                      : useAddress
                      ? "border-primary"
                      : "border"
                  } h-100`}
                  disabled={isAddressMatch}
                  style={{ opacity: useAddress ? 1 : 0.8 }}
                  onClick={() => {
                    setUseAddress(!useAddress);
                  }}
                >
                  <div className="fs-6 text-dark fw-bold d-flex align-items-center">
                    <span>Parcel Address</span>
                    {isAddressMatch ? (
                      <span className="badge text-info ml-auto">
                        <i className="fa fa-check-circle mr-1" />
                        Address applied
                      </span>
                    ) : useAddress ? (
                      <span
                        className="badge badge-primary ml-auto rounded-circle d-flex align-items-center justify-content-center"
                        style={{ width: 20, height: 20 }}
                      >
                        <i className="fa fa-check fa-sm " />
                      </span>
                    ) : null}
                  </div>
                  <div className="text-muted">
                    <div>{selectedParcel?.address?.address}</div>
                    <span>
                      {[
                        selectedParcel?.address?.city,
                        selectedParcel?.address?.state,
                      ]
                        ?.filter(Boolean)
                        ?.join(", ")}
                    </span>{" "}
                    <span>{selectedParcel?.address?.zip}</span>
                    {selectedParcel?.address?.county && (
                      <div>{selectedParcel?.address?.county}</div>
                    )}
                  </div>
                </button>
              </div>
              <div className="w-50 p-2 text-muted d-flex flex-column">
                <button
                  className={`btn bg-white rounded-xl border-2 text-left ${
                    isOwnerMatch ? "" : useOwner ? "border-primary" : "border"
                  } h-100`}
                  disabled={isOwnerMatch}
                  style={{ opacity: useOwner ? 1 : 0.8 }}
                  onClick={() => {
                    setUseOwner(!useOwner);
                  }}
                >
                  <div className="fs-6 text-dark fw-bold d-flex align-items-center">
                    <span>Owner Information</span>
                    {isOwnerMatch ? (
                      <span className="badge text-info ml-auto">
                        <i className="fa fa-check-circle mr-1" />
                        Owner applied
                      </span>
                    ) : useOwner ? (
                      <span
                        className="badge badge-primary ml-auto rounded-circle d-flex align-items-center justify-content-center"
                        style={{ width: 20, height: 20 }}
                      >
                        <i className="fa fa-check fa-sm " />
                      </span>
                    ) : null}
                  </div>
                  <div className="text-muted">
                    <div>{selectedParcel?.owner?.name}</div>
                    <div>{selectedParcel?.owner?.address}</div>
                    <span>
                      {[
                        selectedParcel?.owner?.city,
                        selectedParcel?.owner?.state,
                      ]
                        ?.filter(Boolean)
                        ?.join(", ")}
                    </span>
                    <span>{selectedParcel?.owner?.zip}</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Collapse>
        {marks && !selectedParcel && (
          <div className="text-muted d-flex align-items-center justify-content-center p-3 border-top border-bottom">
            No parcel selected
          </div>
        )}
        <div className="d-flex justify-content-between w-100 align-items-center px-3 py-2 border-top">
          <button className="btn btn-outline-secondary" onClick={toggle}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            disabled={
              !selectedParcel ||
              !((useAddress && !isAddressMatch) || (useOwner && !isOwnerMatch))
            }
            onClick={() => {
              let payload = {};
              if (useAddress) {
                payload.address = selectedParcel?.address;
              }
              if (useOwner) {
                payload.owner = selectedParcel?.owner;
              }
              onChange(payload);
              toggle();
            }}
          >
            Apply
            <i className="fa fa-arrow-right fa-xs ml-2" />
          </button>
        </div>
      </div>
    </Modal>
  );
}
