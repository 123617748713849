import React, { Fragment, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { can } from "../../utils/can";

import { JobAppContext } from "../../GlobalState";
import { Collapse, Navbar, Nav, NavItem } from "reactstrap";
import { NavLink } from "../partials/NavLink";
import OtherLink from "../partials/Link";

import { SimpleFooter } from "./Footer";

import BrandImage from "../../assets/images/ncs-logo-dark-theme.png";
import "./LeftNav.css";

/*
const getTitle = () => {
	const company = JSON.parse(localStorage.getItem('company'))
	if (company !== null) {
		return `${company.name}`
	}
	return 'Job Information'
}
*/

let openSidebar = () => {
  let leftNav = document.getElementById("left-hand-nav");
  let sidebarLinks = leftNav.firstChild;

  leftNav.style.width = "250px";
  sidebarLinks.style.display = "block";

  document.addEventListener("click", closeSideBar);
};

let closeSideBar = () => {
  let leftNav = document.getElementById("left-hand-nav");
  let sidebarLinks = leftNav.firstChild;

  leftNav.style.width = "";
  sidebarLinks.style.display = "none";

  document.removeEventListener("click", closeSideBar);
};

const LeftNav = (props) => {
  const { user, signOut } = useContext(JobAppContext);

  const [isOpen, setIsOpen] = useState(false);

  /*
	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	}
	*/

  return (
    <Navbar id="left-hand-nav" expand="sm" className="sidebar">
      {/*<NavbarToggler onClick={this.toggleNavbar} className="fa-fw mr-2 mb-2" />*/}
      <Collapse
        isOpen={isOpen}
        navbar
        className="sidebar-sticky d-flex flex-column"
      >
        <div style={{ padding: "5px", paddingBottom: "8px" }}>
          <Link to="/jobs" className="ml-2">
            <img
              src={BrandImage}
              alt="NCS Job App"
              style={{ height: "auto", width: 150 }}
              className="mr-4"
            />
          </Link>
        </div>

        <Nav className="mt-4 nav-links" navbar vertical style={{ flexGrow: 1 }}>
          <div className='nav-group'>
            <div className='nav-label'>Job Lists</div>
            <NavLink to="/jobs/open" highlight="open">
              <span className="fa fa-folder-open fa-fw mr-2" />
              <span className="d-xs-inline d-sm-none d-xl-inline">Open</span>
            </NavLink>
            <NavLink to="/jobs/review" highlight="review">
              <span className="fa fa-eye fa-fw mr-2" />
              <span className="d-xs-inline d-sm-none d-xl-inline">Review</span>
            </NavLink>
            <NavLink to="/jobs/submitted" highlight="submitted">
              <span className="fa fa-check fa-fw mr-2" />
              <span className="d-xs-inline d-sm-none d-xl-inline">
                Submitted
              </span>
            </NavLink>
            <NavLink to="/jobs/archived" highlight="archived">
              <span className="fa fa-archive fa-fw mr-2" />
              <span className="d-xs-inline d-sm-none d-xl-inline">Archived</span>
            </NavLink>
          </div>

          <div className='nav-group'>
            <div className='nav-label'>Other</div>
            <NavLink to="/forms" highlight="forms">
              <span className="fa fa-columns fa-fw mr-2" />
              <span className="d-xs-inline d-sm-none d-xl-inline">Forms</span>
            </NavLink>

            {can(["users"], user) && (
              <NavLink to="/users" highlight="users">
                <span className="fa fa-users fa-fw mr-2" />
                <span className="d-xs-inline d-sm-none d-xl-inline">Users</span>
              </NavLink>
            )}
          </div>

          <NavLink
            to="/profile"
            highlight="profile"
            classes="d-block d-sm-none"
          >
            <span className="far fa-user fa-fw mr-2" />
            <span className="d-xs-inline d-sm-none d-xl-inline">
              {user.name}
            </span>
          </NavLink>

          <li className="NavLink d-block d-sm-none">
            <a href="/" className="nav-link subtle" onClick={() => signOut()}>
              <span className="fas fa-sign-out-alt mr-2" />
              <span>Logout</span>
            </a>
          </li>
        </Nav>

        <ul className="navbar-nav flex-column nav-links nav-bottom d-none d-sm-block">
          <NavLink to="/profile" highlight="profile">
            <span className="fas fa-user fa-fw mr-2" />
            <span className="d-xs-inline d-sm-none d-xl-inline">
              {user.name}
            </span>
          </NavLink>
          <li className="NavLink">
            <a href="/" className="nav-link subtle" onClick={() => signOut()}>
              <span className="fas fa-sign-out-alt fa-fw mr-2" />
              <span className="d-none d-xl-inline">Logout</span>
            </a>
          </li>
        </ul>

        <SimpleFooter />
      </Collapse>
    </Navbar>
  );
};
export default LeftNav;
